import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Member } from 'store/member/reducer';
import { Actions } from './reducer';

const Community = (dispatch) => ({
  index: (communitiesId = []) => communitiesId.forEach((communityId) => {
    firestore.collection('communities').doc(communityId)
      .onSnapshot((doc) => {
        dispatch(Member.loadCommunity({ id: doc.id, ...doc.data() }))
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  }),

  show: (communityId) => {
    firestore.collection('communities').doc(communityId)
      .onSnapshot((community) => {
        dispatch(Actions.loadCommunity({ id: community.id, ...community.data() }));
      });
  },

  update: (id, data) => {
    firestore.collection('communities').doc(id)
      .set({ ...data, updatedAt: new Date() }, { merge: true })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
});

export default Community;
