import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isSameDay, format, differenceInCalendarDays } from 'date-fns';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

// Controller
import useCommentController from 'store/article/comment/controller';

// Assets
import { Send, User } from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import styles from './style';

function Community() {
  const classes = styles();
  const dispatch = useDispatch();
  
  const { communityId, articleId } = useParams();

  // Controller
  const Comment = useCommentController(dispatch, communityId, articleId);

  const { current: member } = useSelector((store) => store.member);
  const { comments } = useSelector((store) => store.article);
  const { members } = useSelector((store) => store.community);

  const input = useRef(null);

  const [commentText, setCommentText] = useState('');
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (!comments) Comment.index(limit);
  }, [Comment, comments, limit]);

  const sendComment = () => {
    Comment.store({ content: commentText, author: member.id });
    setCommentText('');
  };

  return (
    <Grid container direction="column" style={{ marginTop: 48 }} id="comment-box">
      <Grid container wrap="nowrap" style={{ marginBottom: 48 }}>
        <Grid container alignItems="flex-start" style={{ width: 'fit-content' }}>
          <Avatar alt={member.name} src={member.avatar} className={classes.commentAvatar}>
            {member.initials}
          </Avatar>
        </Grid>

        <TextField
          id="comment-input"
          ref={input}
          fullWidth
          multiline
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onFocus={() => {
            input.current.style.transform = 'scale(1.01)';
            input.current.style.boxShadow = '1px 5px 29px 0 rgba(81,77,92,0.1)';
          }}
          onBlur={() => {
            input.current.style.transform = 'scale(1)';
            input.current.style.boxShadow = '1px 5px 26px 0 rgba(81,77,92,0.1)';
          }}
          className={classes.commentInput}
          placeholder="Comente algo..."
        />

        <Grid container alignItems="flex-end" style={{ width: 'fit-content' }}>
          <Tooltip title="Enviar comentário">
            <IconButton color="primary" onClick={sendComment}>
              <Send />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {comments ? comments.map((comment) => {
        const author = { name: 'Desconhecido', initials: <User /> };
        const memberAuthor = members.find((m) => m.id === comment.author);
        if (memberAuthor) {
          author.name = memberAuthor.name;
          author.initials = memberAuthor.initials;
          author.avatar = memberAuthor.avatar;
        }

        let date = '';
        const now = new Date();
        const createdAt = comment.createdAt.toDate();
      
        if (isSameDay(now, createdAt)) {
          date = format(createdAt, "'Hoje às 'HH:mm");
        } else {
          const diffDays = differenceInCalendarDays(now, createdAt);
          if (diffDays === 1) date = format(createdAt, "'Ontem às 'HH:mm");
          else date = format(createdAt, "dd/MM/yyyy' às 'HH:mm");
        }

        return (
          <Grid container direction="column" className={classes.commentBox}>
            <Grid container alignItems="center">
              <Avatar alt={author.name} src={author.avatar} className={classes.commentBoxAvatar}>
                {author.initials}
              </Avatar>
              <div className={classes.commentInfo}>
                <p>{author.name}</p>
                <span>{date}</span>
              </div>
            </Grid>
            <Grid container className={classes.commentInput} style={{ margin: '12px 0' }}>
              {comment.content}
            </Grid>
          </Grid>
        );
      }) : (
        <Loading ready={!!comments} content={300} />
      )}
    </Grid>
  );
}

export default Community;
