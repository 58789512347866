import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isSameDay, format, differenceInCalendarDays } from 'date-fns';

// Material UI Components
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// Controller
import useArticleController from 'store/article/controller';
import useLikeController from 'store/article/like/controller';

// Assets
import { MessageCircle, ArrowLeft, Heart, MessageSquare } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import logo from 'assets/logo.png';
import styles from './style';
import Comments from './comments';
import Chat from '../../chat';

function Community() {
  const classes = styles();
  const dispatch = useDispatch();
  
  const history = useHistory();
  const { communityId, articleId } = useParams();

  // Controller
  const Article = useArticleController(dispatch, communityId);
  const Like = useLikeController(dispatch, communityId, articleId);

  const { current: community } = useSelector((store) => store.community);
  const { current: member } = useSelector((store) => store.member);
  const { current: article, like } = useSelector((store) => store.article);

  const iframe = useRef(null);

  const [openChat, setOpenChat] = useState(false);
  const [postAt, setPostAt] = useState(null);
  const [currentLike, setCurrentLike] = useState(0);

  useEffect(() => {
    if (!article) Article.show(articleId);
    else if (article.id !== articleId) Article.clear();
  }, [Article, article, articleId]);

  useEffect(() => {
    if (like === null) Like.show(member.id);
  }, [Like, like, member.id]);

  useEffect(() => {
    if (article) {
      let date = '';
      const now = new Date();
      const createdAt = article.createdAt.toDate();
    
      if (isSameDay(now, createdAt)) {
        date = format(createdAt, "'Postado hoje às 'HH:mm");
      } else {
        const diffDays = differenceInCalendarDays(now, createdAt);
        if (diffDays === 1) date = format(createdAt, "'Postado ontem às 'HH:mm");
        else date = format(createdAt, "'Postado 'dd/MM/yyyy' às 'HH:mm");
      }

      setPostAt(date);
    }
  }, [article]);

  const handleLike = async () => {
    if (like) {
      setCurrentLike(-1);
      await Like.destroy(member.id);
    } else {
      setCurrentLike(1);
      await Like.store(member.id);
    }
    setCurrentLike(0);
  };

  const scrollTo = () => {
    document.getElementById('comment-input').focus();
    const offset = document.getElementById('comment-box').offsetTop - 96;
    const content = document.getElementById('content');
    content.scrollTop = offset;
  };

  return (
    <Grid container className={classes.mainContainer} id="content">
      <AppBar position="fixed" className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center" style={{ width: 'fit-content' }}>
            <IconButton color="primary" onClick={history.goBack} style={{ marginLeft: -16 }}>
              <ArrowLeft size={18} />
            </IconButton>
            <Typography className={classes.toolbarTitle}>
              {community && community.idealize.name}
            </Typography>
          </Grid>
          <img src={logo} alt="Logo hybri" className={classes.logo} />
          <Avatar className={classes.userAvatar}>GO</Avatar>
        </Toolbar>
      </AppBar>

      <Grid container alignItems="flex-start">
        {article && like !== null ? (
          <>
            {(article.cover || article.video) && (
              <Grid
                container
                className={classes.cover}
                style={{
                  backgroundImage: `url(${article.cover || (article.video && `https://img.youtube.com/vi/${article.video}/hqdefault.jpg`)})`,
                }}
              />
            )}

            <Grid container direction="column" alignItems="center" className={classes.reactions}>
              <Grid container direction="column" className={classes.likes}>
                <Tooltip title="Amei" placement="right">
                  <IconButton color="secondary" onClick={handleLike}>
                    <Heart style={{ fill: like ? '#8ec2ff' : 'transparent' }} />
                  </IconButton>
                </Tooltip>
                <p>{article.likes + currentLike}</p>
              </Grid>
              <Tooltip title="Comentar" placement="right">
                <IconButton color="secondary" className={classes.commentButton} onClick={scrollTo}>
                  <MessageSquare />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid container direction="column" className={classes.content}>
              {article.video && (
                <iframe
                  ref={iframe}
                  title={article.title}
                  width="800"
                  height="450"
                  src={`https://www.youtube.com/embed/${article.video}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className={classes.iframe}
                />
              )}
              {article.cover && (
                <img alt={article.title} src={article.cover} className={classes.iframe} />
              )}
              <Typography
                className={classes.articleTitle}
                style={{ marginTop: article.cover || article.video ? 32 : 96 }}
              >
                {article.title}
              </Typography>
              {article.teaser && (
                <Typography className={classes.articleTeaser}>{article.teaser}</Typography>
              )}
              <Typography className={classes.articleDate}>{postAt}</Typography>
              {article.content && (
                <div className={classes.divider} />
              )}
              <div
                className={classes.articleContent}
                dangerouslySetInnerHTML={{ __html: article.content }}
              />

              <Comments />
            </Grid>
          </>
        ) : (
          <Loading ready={!!article && like !== null} />
        )}
      </Grid>

      <Fab
        color="secondary"
        className={classes.chatFab}
        onClick={() => setOpenChat(true)}
      >
        <Badge color="primary" classes={{ badge: classes.badge }}>
          <MessageCircle />
        </Badge>
      </Fab>
      <Chat open={openChat} onClose={() => setOpenChat(false)} />
    </Grid>
  );
}

export default Community;
