import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    margin: '0 32px 64px',
    width: '100%',
    padding: '0 16px',
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',

    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '1.5s',
    animationIterationCount: 'infinite',
  },
  btnMore: {
    margin: -32,
    width: 'calc(100% + 64px)',
    marginBottom: -52,
    padding: '12px 0',
  },
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  channel: {
    margin: '0 -32px',
    width: 'calc(100% + 64px)',
    borderRadius: 0,
    padding: '16px 32px',
    textAlign: 'left',
    textTransform: 'inherit',
  },
  channelAvatar: {
    background: theme.palette.primary.main,
    marginRight: theme.spacing(),
  },
  channelMsg: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.common.grey,
  },
  channelUnread: {
    width: 16,
    minWidth: 16,
    height: 16,
    background: theme.palette.secondary.main,
    borderRadius: 8,
    marginLeft: theme.spacing(),
  },
}));
