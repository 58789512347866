import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

// Controllers
import useMessageController from 'store/channel/message/controller';

// Icons and Styles
import { ArrowLeft, MessageSquare } from 'react-feather';
import styles from './style';

function UserProfile({ user: id, onClose, openChat }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { commnunityId } = useParams();

  // Controllers
  const Message = useMessageController(dispatch, commnunityId);

  const { members } = useSelector((store) => store.community);

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (id) {
      const data = members.find((u) => u.id === id);
      if (data) setUser(data);
      else onClose();
    }
  }, [id, members, onClose]);

  return (
    <Drawer anchor="right" open={!!id} className={classes.drawer}>
      <Grid container alignItems="center" className={classes.content} style={{ padding: 16 }}>
        <IconButton color="primary" onClick={onClose}>
          <ArrowLeft />
        </IconButton>
        <Typography className={classes.title}>PERFIL</Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        direction="column"
        wrap="nowrap"
        spacing={2}
        className={classes.content}
        style={{ margin: 0, height: 'calc(100% - 80px)', overflow: 'overlay' }}
      >
        {user && (
          <>
            <Grid item style={{ position: 'relative' }}>
              <Avatar alt={user.name} src={user.avatar} className={classes.avatar}>
                {user.initials}
              </Avatar>
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
              <Typography className={classes.name}>{user.name}</Typography>
              {/* <Typography className={classes.office}>{user.office}</Typography>
              <Typography className={classes.email}>{user.email}</Typography> */}
            </Grid>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={() => {
                openChat(id);
                Message.clear();
                onClose();
              }}
            >
              <MessageSquare size={20} style={{ marginRight: 16 }} />
              CONVERSA PRIVADA
            </Button>
          </>
        )}
      </Grid>
    </Drawer>
  );
}

UserProfile.propTypes = {
  user: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired,
};

UserProfile.defaultProps = {
  user: null,
};

export default UserProfile;
