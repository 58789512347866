import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    margin: '0 auto',
    padding: `0 ${theme.spacing()}px`,
    zIndex: 10,
  },
  logo: {
    display: 'block',
    height: 90,
    margin: `${theme.spacing(2)}px auto`,
    zIndex: 10,
  },
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  title: {
    position: 'relative',
    zIndex: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 24,
    textShadow: '0 0 30px rgba(255, 255, 255)',
    margin: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  passwordInput: {
    width: '100%',
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    boxShadow: '1px 4px 25px rgba(255,255,255,0.4)',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    width: '100%',
    margin: `${theme.spacing()}px 0`,
  },
  iconError: {
    opacity: 0.9,
    marginRight: theme.spacing(),
    minWidth: 20,
  },
  resetPassword: {
    float: 'right',
    margin: '10px 0',
    position: 'relative',
    zIndex: 10,
    paddingTop: theme.spacing(),
  },
}));
