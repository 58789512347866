import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controller
import usePlanController from 'store/community/plan/controller';

// Icons, Commons and Styles
import { X, Star } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Loading from 'common/loading';
import styles from './style';

function EventForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { communityId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Plan = usePlanController(dispatch, communityId)

  const { current: community, plans } = useSelector((store) => store.community);

  useEffect(() => {
    if (!plans) Plan.index();
  }, [Plan, plans]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      scroll="body"
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span style={{ textTransform: 'uppercase' }}>
            {`BENEFÍCIOS DOS TRIPULANTES DA ${community && community.idealize.name}`}
          </span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {plans ? (
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.planTitle}>
              {plans[0].name}
            </Typography>
            <Grid container direction="column" className={classes.planBenefits}>
              {plans[0].benefits.map((benefit) => (
                <Grid container alignItems="center" style={{ padding: '8px 0' }}>
                  <Star size={18} color="#E8DA13" style={{ marginRight: 16, fill: '#E8DA13' }} />
                  <Typography>{benefit}</Typography>
                </Grid>
              ))}
            </Grid>
            {/* <Typography className={classes.planPrice}>
              {plans[0].price === 0 ? 'GRÁTIS' : numberToReal(plans[0].price)}
            </Typography>
            <Button color="primary" variant="contained" style={{ marginTop: 32 }}>
              ASSINE AGORA
            </Button> */}
          </Grid>
        ) : (
          <Loading ready={!!plans} content={200} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EventForm;
