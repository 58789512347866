import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Assets and Styles
import gif404 from 'assets/404.json';
import video from 'assets/auth-background.vid';
import styles from './style';

function Error404() {
  const classes = styles();
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: gif404,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  return (
    <Grid container>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>

      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Lottie isClickToPauseDisabled options={defaultOptions} width="80%" height="80%" />
          <Typography className={classes.title}>
            EITA! NÓS ESTAMOS FORA DA NOSSA ROTA. NÃO FAÇO IDEIA DE QUE LUGAR É ESSE.
          </Typography>
          <Fab
            variant="extended"
            color="secondary"
            size="large"
            className={classes.button}
            onClick={history.goBack}
          >
            É MELHOR VOLTARMOS
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Error404;
