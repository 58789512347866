/**
 * Types
 */
const Types = {
  LOAD_EVENT: '@event/LOAD_EVENT',
  LOAD_CONFIRMED: '@event/LOAD_CONFIRMED',
  LOAD_INTERESSED: '@event/LOAD_INTERESSED',
  CLEAR: '@event/CLEAR',
};

/**
 * Actions
 */
export const Actions = {
  loadEvent: (current) => ({ type: Types.LOAD_EVENT, payload: { current } }),
  loadConfirmed: (confirmed) => ({ type: Types.LOAD_CONFIRMED, payload: { confirmed } }),
  loadInteressed: (interessed) => ({ type: Types.LOAD_INTERESSED, payload: { interessed } }),
  clear: () => ({ type: Types.CLEAR }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  confirmed: null,
  interessed: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_EVENT: {
      const { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_CONFIRMED: {
      const { confirmed } = action.payload;
      return { ...state, confirmed };
    }
    case Types.LOAD_INTERESSED: {
      const { interessed } = action.payload;
      return { ...state, interessed };
    }
    case Types.CLEAR: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
