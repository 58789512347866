import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { X, MessageCircle } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import styles from './style';

function CommunityMembers() {
  const classes = styles();

  const history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { current } = useSelector((store) => store.member);
  const { members } = useSelector((store) => store.community);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      scroll="body"
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid container alignItems="center">
            <span style={{ lineHeight: 1 }}>MEMBROS</span>
            <span style={{ fontSize: 16, color: '#777', lineHeight: 1, marginLeft: 16 }}>
              {members && `(${members.length})`}
            </span>
          </Grid>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {members ? (
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar alt={current.name} src={current.avatar}>
                  {current.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${current.name} (você)`}
                primaryTypographyProps={{ style: { fontStyle: 'italic' } }}
              />
            </ListItem>
            {members.filter((m) => m.id !== current.id).sort((a, b) => a.name - b.name)
              .map((member) => (
                <ListItem key={member.id}>
                  <ListItemAvatar>
                    <Avatar alt={member.name} src={member.avatar} className={classes.avatar}>
                      {member.initials}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={member.name} />
                  {/* <ListItemSecondaryAction>
                    <Tooltip title="Começar uma conversa" placement="left">
                      <IconButton color="primary" onClick={() => newChat(member.id)}>
                        <MessageCircle size={18} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction> */}
                </ListItem>
              ))}
          </List>
        ) : (
          <Loading ready={!!members} content={200} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CommunityMembers;
