import { makeStyles, darken, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    padding: '12px 24px 24px',
    lineHeight: 1.5,
    color: theme.palette.common.grey,
  },
  path: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    margin: '0 -24px 24px',
    width: 'calc(100% + 48px)',
    background: darken(theme.palette.secondary.main, 0.9),
  },
  folder: {
    padding: theme.spacing(),
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    width: 200,
    overflow: 'hidden',
    margin: 8,
    cursor: 'pointer',
    color: theme.palette.common.black,
    '&:hover': {
      background: fade('#fff', 0.1),
    },
  },
  folderName: {
    fontSize: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  file: {
    padding: theme.spacing(),
    borderRadius: 4,
    boxShadow: '0px 2px 12px 0 rgba(81,77,92,0.05)',
    width: 'calc(100% - 16px)',
    overflow: 'hidden',
    margin: '0 8px',
    cursor: 'pointer',
    color: theme.palette.common.black,
    '&:hover': {
      background: fade('#fff', 0.1),
    },
  },
  noData: {
    fontSize: 18,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
  },
}));
