import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

// Firebase Config
import { configDev, configMaster } from './accounts-key';

if (process.env.NODE_ENV === 'development') {
  firebase.initializeApp(configDev);
} else {
  firebase.initializeApp(configMaster);
}

export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const fireauth = firebase.auth();

export const { Timestamp } = firebase.firestore;

export const ERRORS = {
  'auth/weak-password': 'A senha precisa ter acima de 6 caracteres',
  'auth/invalid-email': 'Precisamos de um e-mail válido',
  'auth/user-not-found': 'Não encontramos esse usuário. Verifique com nossa equipe de suporte.',
  'auth/wrong-password': 'Essa senha não confere com a que temos aqui',
  'auth/too-many-requests': 'Várias tentativas sem sucesso. Aguarde alguns minutos e tente novamente.',
  'auth/email-already-in-use': 'Já existe um usuário com esse e-mail em nossa plataforma.',
  'permission-denied': 'Você não tem permissão para acessar esses dados.',
  'already-exists': 'Já existe um usuário com esse e-mail em nossa plataforma.',
};

export default firebase;
