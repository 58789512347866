import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from '../reducer';

const Comment = (dispatch, communityId, articleId) => ({
  index: async (limit = 10) => {
    firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('comments').orderBy('createdAt', 'desc').limit(limit)
      .onSnapshot(async (snapshot) => {
        const comments = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        dispatch(Actions.loadComments(comments));
      });
  },

  store: (data) => {
    const commentsRef = firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('comments');

    return commentsRef.add({ ...data, createdAt: new Date() })
      .then(async () => {
        const comments = await commentsRef.get();

        firestore.collection('communities').doc(communityId)
          .collection('articles').doc(articleId)
          .set({ comments: comments.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: (id) => {
    const commentsRef = firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('comments');
  
    return commentsRef.doc(id).delete()
      .then(async () => {
        const comments = await commentsRef.get();

        firestore.collection('communities').doc(communityId)
          .collection('articles').doc(articleId)
          .set({ comments: comments.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },
});

export default Comment;
