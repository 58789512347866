/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { storage } from 'services/firebase';

// Emoji
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

// Material UI Components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
// import Tab from '@material-ui/core/Tab';
// import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useChannelController from 'store/channel/controller';
import useMessageController from 'store/channel/message/controller';

// Icons and Styles
import { Send, Smile, Paperclip } from 'react-feather';
import AttachDialog from './attachments/dialog';
import UploadButton from './attachments/button';
import styles from './style';

function TabPanel({ children, value, index }) {
  const theme = useTheme();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      dir={theme.direction}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function SenderBox({ channel, chatUser, newChat }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { communityId } = useParams();

  // Controller
  const Channel = useChannelController(dispatch, communityId);
  const Message = useMessageController(dispatch, communityId);

  const { current: member } = useSelector((store) => store.member);
  const { members } = useSelector((store) => store.community);

  const [content, setContent] = useState('');
  const [openEmoji, setOpenEmoji] = useState(null);
  const [openAttachs, setOpenAttachs] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [uploadRef, setUploadRef] = useState(null);
  // const [tab, setTab] = useState(0);

  const sendMessage = async () => {
    if (content !== '') {
      let channelId = null;

      if (channel) channelId = channel.id;
      else if (chatUser) {
        channelId = await Channel.store({ members: [chatUser, member.id] });
        newChat({ id: channelId, members: [chatUser, member.id] });
      }

      Message.store(channelId, { content, attachments, sender: member.id });
      setContent('');
    }
  };

  const sendAttachments = async (fileName, e) => {
    const { name, size } = e.blob_.data_;
    const { fullPath } = e.metadata_;

    const file = attachments.find((a) => a.name === name && a.size === size);
    if (file) {
      const url = await storage.ref(fullPath).getDownloadURL();

      let channelId = null;

      if (channel) channelId = channel.id;
      else if (chatUser) {
        channelId = await Channel.store({ members: [chatUser, member.id] });
        newChat(channelId);
      }

      Message.store(channelId, {
        content: '',
        sender: member.id,
        attachments: [{ name: fileName, label: name, size, url }],
      });

      if (file === attachments.sort((a, b) => b.size - a.size)[0]) {
        setAttachments([]);
        setOpenAttachs(false);
      }
    }
  };

  const addEmoji = (e) => {
    console.log(e);
    const emoji = e.native;
    setContent(content + emoji);
  };

  const emojiLabels = {
    search: 'Procurar',
    clear: 'Limpar', // Accessible label on "clear" button
    notfound: 'Nenhum emoji encontrado',
    categories: {
      search: 'Resultados encontrados',
      recent: 'Recentes',
      people: 'Emoções & Pessoas',
      nature: 'Animais & Natureza',
      foods: 'Comida & Bebidas',
      activity: 'Atividades',
      places: 'Viagens & Lugares',
      objects: 'Objetos',
      symbols: 'Símbolos',
      flags: 'Flags',
      custom: 'Customizados',
    },
    categorieslabel: 'Categorias',
  };

  return (
    <Grid container style={{ padding: 16 }}>
      {openAttachs && (
        <AttachDialog
          channel={channel}
          chatUser={chatUser}
          attachments={attachments}
          onChange={setAttachments}
          send={() => {
            if (uploadRef) {
              attachments.forEach((file) => {
                uploadRef.startUpload(file);
              });
            }
          }}
          onClose={() => setOpenAttachs(false)}
        />
      )}

      <TextField
        multiline
        fullWidth
        autoFocus
        variant="outlined"
        placeholder="Escreva algo..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          }
        }}
        InputProps={{
          inputProps: { style: { maxHeight: 150, overflow: 'auto' } },
          style: {
            padding: '20px 10px', lineHeight: 1.4, borderRadius: 31, background: '#212121'
          },
          startAdornment: (
            <InputAdornment poistion="start">
              <IconButton onClick={(e) => setOpenEmoji(e.currentTarget)}>
                <Smile />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {content === '' && (
                <InputAdornment poistion="end">
                  <IconButton>
                    <UploadButton
                      className={classes.labelListUpload}
                      channel={channel ? channel.id : chatUser}
                      onChange={(files) => {
                        setAttachments(files);
                        if (files.length > 0) setOpenAttachs(true);
                      }}
                      onStart={() => {}}
                      onSuccess={sendAttachments}
                      setRef={setUploadRef}
                    >
                      <Paperclip size={18} />
                    </UploadButton>
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment poistion="end">
                <IconButton color="primary" onClick={sendMessage}>
                  <Send />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <Popover
        open={!!openEmoji}
        anchorEl={openEmoji}
        onClose={() => setOpenEmoji(null)}
        style={{ marginTop: -48 }}
      >
        {/* <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newTab) => setTab(newTab)}
          aria-label="disabled tabs example"
        >
          <Tab label="EMOJI" />
          <Tab label="GIF" />
        </Tabs>

        <TabPanel value={tab} index={0}> */}
        <Picker
          color="#c0a5ff"
          theme="dark"
          onSelect={addEmoji}
          showPreview={false}
          showSkinTones={false}
          i18n={emojiLabels}
        />
        {/* </TabPanel>
        <TabPanel value={tab} index={1}>
          Item Two
        </TabPanel> */}
      </Popover>
    </Grid>
  );
}

SenderBox.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string,
    group: PropTypes.object,
    members: PropTypes.array,
  }),
  chatUser: PropTypes.string,
};

SenderBox.defaultProps = {
  chatUser: null,
  channel: null,
};

export default SenderBox;
