import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  profile: {
    padding: theme.spacing(2),
    // background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
  },
  communityAvatar: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: `4px solid ${theme.palette.secondary.main}`,
    width: 120,
    height: 120,
    fontSize: 40,
    marginRight: theme.spacing(2),
    boxShadow: '0 0 80px rgba(142, 194, 255, 0.8)',
  },
  communityName: {
    color: theme.palette.common.black,
    fontSize: 40,
    fontWeight: 600,
    textShadow: '0px 0px 40px #fff',
  },
  communityDesc: {
    color: theme.palette.common.grey,
    marginTop: theme.spacing(),
    textShadow: '0px 0px 16px #fff',
  },
  memberAvatar: {
    background: theme.palette.primary.main,
    marginRight: -8,
    border: `3px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    '&:last-child': { margin: 0 },
  },
}));
