import React from 'react';
import PropTypes from 'prop-types';

export const renderRoutes = (routes) => routes.map(({ routerComponent: Component, ...route }) => (
  <Component
    key={route.path}
    path={route.path}
    component={route.component}
    exact={route.exact}
    childrenRoutes={route.childrenRoutes}
  />
));

export const propRoutes = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired,
    routerComponent: PropTypes.elementType.isRequired,
    exact: PropTypes.bool,
    childrenRoutes: PropTypes.arrayOf(PropTypes.object),
  }),
);
