import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    zIndex: 5,
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  },
  imageContainer: {
    position: 'relative',
    maxWidth: 200,
    maxHeight: 200,
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
