import { lastDayOfMonth, subMonths, addMonths } from 'date-fns';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Community } from 'store/community/reducer';

import { Actions } from './reducer';

const Event = (dispatch, companyId) => ({
  index: (month, year) => {
    const start = new Date(`${year}-${month}-01 00:00:00`);
    const lastDay = lastDayOfMonth(start);
    const end = new Date(`${year}-${month}-${lastDay.getDate()} 23:59:59`);

    console.log(subMonths(start, 1));
    console.log(addMonths(end, 1));
    
    firestore.collection('events')
      .where('company', '==', companyId)
      .where('showInCommunity', '==', true)
      .where('deletedAt', '==', null)
      .where('dates.start', '>=', subMonths(start, 1))
      .where('dates.start', '<=', addMonths(end, 1))
      .orderBy('dates.start', 'asc')
      .onSnapshot((snap) => {
        const events = snap.docs.map((event) => ({ id: event.id, ...event.data() }));
        dispatch(Community.loadEvents(events));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  indexHome: () => {
    firestore.collection('events')
      .where('company', '==', companyId)
      .where('showInCommunity', '==', true)
      .where('deletedAt', '==', null)
      .where('dates.start', '>=', new Date())
      .orderBy('dates.start', 'asc').limit(3)
      .onSnapshot((snap) => {
        const events = snap.docs.map((event) => ({ id: event.id, ...event.data() }));
        dispatch(Community.loadHomeEvents(events));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  show: (eventId) => {
    firestore.collection('events').doc(eventId).onSnapshot(
      (event) => {
        dispatch(Actions.loadEvent({ id: eventId, ...event.data() }));
      },
      (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        dispatch(Actions.loading(false));
      },
    );
  },

  store: async (data) => firestore.collection('events')
    .add({ ...data, deletedAt: null, createdAt: new Date(), updatedAt: new Date() })
    .then((doc) => {
      if (!data.draft) {
        dispatch(Alert.show({ message: 'Evento criando com sucesso.', type: 'success' }));
      } else {
        dispatch(Alert.show({ message: 'Evento salvo com sucesso.', type: 'success' }));
      }
      return doc.id;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    }),

  update: async (id, data, opt = { withoutAlert: false }) => firestore.collection('events').doc(id)
    .set({ ...data, updatedAt: new Date() }, { merge: true })
    .then(() => {
      if (!opt.withoutAlert) {
        dispatch(Alert.show({ message: 'Evento alterado com sucesso.', type: 'success' }));
      }
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  destroy: (id) => firestore.collection('events').doc(id)
    .set({ deletedAt: new Date() }, { merge: true })
    .then(() => {
      dispatch(Alert.show({ message: 'Evento excluido com sucesso.', type: 'success' }));
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  forceDestroy: (id) => firestore.collection('events').doc(id)
    .delete()
    .then(() => {
      dispatch(Alert.show({ message: 'Evento excluido com sucesso.', type: 'success' }));
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  clear: () => {
    dispatch(Actions.clear());
  },
});

export default Event;
