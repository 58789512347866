import React, { useState, useEffect } from 'react';
import StackGrid from "react-stack-grid";
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isSameDay, format, differenceInCalendarDays } from 'date-fns';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controller
import useArticleController from 'store/article/controller';

// Assets
import { Heart, MessageSquare, PlayCircle } from 'react-feather';
import styles from './style';

function CommunityContents() {
  const classes = styles();
  const dispatch = useDispatch();
  
  const history = useHistory();
  const { communityId } = useParams();

  // Controller
  const Article = useArticleController(dispatch, communityId);

  const { articles } = useSelector((store) => store.community);

  const [limit, setLimit] = useState(8);

  useEffect(() => {
    if (!articles) Article.index(limit);
  }, [Article, articles, limit]);

  return (
    <Grid container style={{ marginTop: 96 }}>
      <Typography className={classes.sectionTitle}>CONTEÚDOS</Typography>

      <Grid container style={{ marginTop: 32 }}>
        <StackGrid style={{ width: '100%' }} columnWidth="50%" gutterWidth={48} gutterHeight={48}>
          {articles && articles.map((article) => {
            let date = '';
            const now = new Date();
            const createdAt = article.createdAt.toDate();
          
            if (isSameDay(now, createdAt)) {
              date = format(createdAt, "'Hoje às 'HH:mm");
            } else {
              const diffDays = differenceInCalendarDays(now, createdAt);
              if (diffDays === 1) date = format(createdAt, "'Ontem às 'HH:mm");
              else date = format(createdAt, "dd/MM/yyyy' às 'HH:mm");
            }

            const delay = 4 * Math.random(); 

            return (
              <Grid
                item
                key={article.id}
                onClick={() => history.push(`/communities/${communityId}/articles/${article.id}`)}
              >
                <Grid container className={classes.article} style={{ animationDelay: `${delay}s` }}>
                  {(article.cover || article.video) && (
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="flex-end"
                      className={classes.articleMedia}
                    >
                      <div
                        className={classes.articleImage}
                        style={{ backgroundImage: `url(${
                          article.cover || `https://img.youtube.com/vi/${article.video}/hqdefault.jpg`
                        })` }}
                      />
                      <Grid
                        container
                        direction="column"
                        justify="flex-end"
                        className={`${classes.articleCover} imgArticle`}
                      >
                        {article.video && (
                          <PlayCircle size={58} className={`${classes.videoPlay} play`} />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container direction="column" style={{ padding: 16 }}>
                    <Typography className={`${classes.articleTitle} title`}>
                      {article.title}
                    </Typography>
                    {!article.cover && !article.video && (
                      <Typography className={classes.articleTeaser}>
                        {article.teaser}
                      </Typography>
                    )}
                    <Grid container justify="space-between" alignItems="center" style={{ paddingTop: 24 }}>
                      <Grid container alignItems="center" className={classes.articleInteractions}>
                        <Heart size={18} style={{ marginRight: 8 }} />
                        <span style={{ marginRight: 16 }}>{article.likes}</span>
                        <MessageSquare size={18} style={{ marginRight: 8 }} />
                        <span>{article.comments}</span>
                      </Grid>
                      <Typography className={classes.articleDate}>{date}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </StackGrid>
      </Grid>
    </Grid>
  );
}

export default CommunityContents;
