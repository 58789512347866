import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { format, isSameDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controller
import useEventController from 'store/event/controller';
import useConfirmedController from 'store/event/confirmed/controller';
import useInteressedController from 'store/event/interessed/controller';

// Icons, Commons and Styles
import { X, Bookmark, Calendar, Clock, CheckCircle, Star } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import AddToCalendar from 'common/add-to-calendar';
import styles from './style';

function EventForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { communityId, eventId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Event = useEventController(dispatch, communityId)
  const Confirmed = useConfirmedController(dispatch, communityId, eventId)
  const Interessed = useInteressedController(dispatch, communityId, eventId)

  const { current: member } = useSelector((store) => store.member);
  const { current: event, interessed, confirmed } = useSelector((store) => store.event);

  const [saveEvent, setSaveEvent] = useState(null);
  const [hover, setHover] = useState('');

  useEffect(() => {
    if (!event) Event.show(eventId);
    else if (event.id !== eventId) Event.clear();
  }, [Event, event, eventId]);

  useEffect(() => {
    if (confirmed === null) Confirmed.show(member.id);
  }, [Confirmed, confirmed, member.id]);

  useEffect(() => {
    if (interessed === null) Interessed.show(member.id);
  }, [Interessed, interessed, member.id]);

  const haveInteressed = () => {
    if (interessed) Interessed.destroy(member.id);
    else Interessed.store(member.id);
  };

  const confirmPresence = () => {
    if (confirmed) Confirmed.destroy(member.id);
    else Confirmed.store(member.id);
  };

  let date = '';

  if (event) {
    const startAt = event.dates.start.toDate();
    const endAt = event.dates.end.toDate();
  
    if (isSameDay(startAt, endAt)) {
      const day = format(startAt, "dd' de 'MMMM' de 'yyyy", { locale: ptBR });
      const timeStart = format(startAt, "HH:mm");
      const timeEnd = format(endAt, "HH:mm");

      date = (
        <Grid container alignItems="center" style={{ marginTop: 8 }}>
          <Calendar style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ marginRight: 16, fontWeight: 600 }}>
            {day}
          </Typography>

          <Clock style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ marginRight: 20, fontWeight: 600 }}>
            {timeStart}
          </Typography>

          <span style={{ marginRight: 12, color: '#5a33b5' }}>》</span>

          <Clock style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ fontWeight: 600 }}>
            {timeEnd}
          </Typography>
        </Grid>
      );
    } else {
      const dayStart = format(startAt, "dd/MM/yyyy");
      const dayEnd = format(endAt, "dd/MM/yyyy");
      const timeStart = format(startAt, "HH:mm");
      const timeEnd = format(endAt, "HH:mm");

      date = (
        <Grid container alignItems="center" style={{ marginTop: 8 }}>
          <Calendar style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ marginRight: 16, fontWeight: 600 }}>
            {dayStart}
          </Typography>

          <Clock style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ marginRight: 20, fontWeight: 600 }}>
            {timeStart}
          </Typography>

          <span style={{ marginRight: 12, color: '#5a33b5' }}>》</span>

          <Calendar style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ marginRight: 16, fontWeight: 600 }}>
            {dayEnd}
          </Typography>

          <Clock style={{ marginRight: 8 }} />
          <Typography className={classes.value} style={{ fontWeight: 600 }}>
            {timeEnd}
          </Typography>
        </Grid>
      );
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      scroll="body"
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          {event && (
            <div
              className={classes.eventCover}
              style={{
                backgroundImage: `url(${event.cover || 'https://hybri.io/img/galaxy-cover-2.jpg'})`,
              }}
            />
          )}
          <IconButton onClick={history.goBack} aria-label="Sair" className={classes.exitButton}>
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {event && event.dates.start.toDate() > new Date() && (
          <Grid
            container
            justify="center"
            style={{ margin: '-58px -24px 16px', zIndex: 100, width: 'calc(100% + 48px)' }}
          >
            {!confirmed && (
              <Button
                color="secondary"
                className={classes.btnStar}
                onClick={haveInteressed}
                onMouseEnter={() => setHover('interessed')}
                onMouseLeave={() => setHover('')}
              >
                {hover === 'interessed' && interessed ? (
                  <X size={18} style={{ marginRight: 16 }} />
                ) : (
                  <Star
                    size={18}
                    style={{ marginRight: 16, fill: interessed ? '#a19527' : 'transparent' }}
                  />
                )}
                {interessed ? hover === 'interessed' ? 'REMOVER INTERESSE' : 'INTERESSADO' : 'TENHO INTERESSE' }
                {/* {event.subscribers && event.subscribers.interest > 0
                  ? `${event.subscribers.interest} INTERESSADO${event.subscribers.interest > 1 ? 'S' : ''}`
                  : 'TENHO INTERESSE'} */}
              </Button>
            )}
            <Button
              color="primary"
              className={classes.btnConfirm}
              onClick={confirmPresence}
              onMouseEnter={() => setHover('confirm')}
              onMouseLeave={() => setHover('')}
            >
              {hover === 'confirm' && confirmed ? (
                <X size={18} style={{ marginRight: 16 }} />
              ) : (
                <CheckCircle size={18} style={{ marginRight: 16 }} />
              )}
              {confirmed ? hover === 'confirm' ? 'REMOVER CONFIRMAÇÃO' : 'PRESENÇA CONFIRMADA' : 'CONFIRMAR PRESENÇA' }
              {/* {event.subscribers && event.subscribers.confirmed > 0
                ? `${event.subscribers.confirmed} CONFIRMADO${event.subscribers.confirmed > 1 ? 'S' : ''}`
                : 'CONFIRMAR PRESENÇA'} */}
            </Button>
          </Grid>
        )}
        {event ? (
          <Grid container direction="column" alignItems="center">

            <Grid container alignItems="flex-start" wrap="nowrap">
              <Typography className={classes.eventName}>
                {event.name}
              </Typography>
              <Tooltip title="Salvar na minha agenda" placement="left">
                <IconButton
                  color="secondary"
                  className={classes.saveButton}
                  onClick={(e) => setSaveEvent(e.currentTarget)}
                >
                  <Bookmark />
                </IconButton>
              </Tooltip>
              <AddToCalendar
                event={{
                  name: event.name,
                  details: event.creating ? event.creating.idealization.description : '',
                  location: '',
                  startsAt: format(event.dates.start.toDate(), "yyyy'-'MM'-'dd'T'HH':'mm':'ssXXX"),
                  endsAt: format(event.dates.end.toDate(), "yyyy'-'MM'-'dd'T'HH':'mm':'ssXXX"),
                }}
                open={saveEvent}
                onClose={() => setSaveEvent(null)}
              />
            </Grid>

            {event.creating && event.creating.idealization.description && (
              <Grid container>
                <Typography className={classes.eventDescription}>
                  {event.creating.idealization.description}
                </Typography>
              </Grid>
            )}
            
            <Grid container direction="column" style={{ padding: '32px 0' }}>
              <Typography className={classes.label}>QUANDO?</Typography>
              <Typography className={classes.value} style={{ fontWeight: 600 }}>
                {date}
              </Typography>
            </Grid>

            {event.creating && event.creating.idealization.objective && (
              <Grid container direction="column" style={{ paddingBottom: 32 }}>
                <Typography className={classes.label}>QUAL O OBJETIVO DO EVENTO?</Typography>
                <Typography className={classes.value} style={{ lineHeight: 1.5 }}>
                  {event.creating.idealization.objective}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Loading ready={!!event} content={200} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EventForm;
