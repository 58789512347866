import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

// Controllers
import useChannelController from 'store/channel/controller';
import useMessageController from 'store/channel/message/controller';

// Icons, Commons and Styles
import { ArrowLeft } from 'react-feather';
import Loading from 'common/loading';
import UserProfile from './profile';
import MessageBox from './message';
import SenderBox from './senderbox';
import styles from './style';

function Chat({ chat, chatUser, onClose, openChat, newChat }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { communityId } = useParams();

  // Controllers
  const Channel = useChannelController(dispatch, communityId);
  const Message = useMessageController(dispatch, communityId);

  const { current: member } = useSelector((store) => store.member);
  const { members } = useSelector((store) => store.community);
  const { messages } = useSelector((store) => store.channel);

  const [openProfile, setOpenProfile] = useState(null);
  const [channel, setChannel] = useState({ name: '', image: null, initials: '', isAdmin: false });
  const [unreadEl, setUnreadEl] = useState(null);
  const [chatEl, setChatEl] = useState(null);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (chat) {
      let chatPrivate = chat.members && chat.members.find((m) => m !== member.id);
      if (chatPrivate) chatPrivate = members.find((m) => m.id === chatPrivate);

      const name = chatPrivate ? chatPrivate.name : chat.name || 'Usuário removido';
      const image = chatPrivate ? chatPrivate.avatar : chat.avatar;
      const initials = chatPrivate ? chatPrivate.initials : chat.initials;

      setChannel({ id: chat.id, name, image, initials });
    } else setOpenProfile(null);
  }, [chat, member.id, members]);

  useEffect(() => {
    if (chatUser) {
      const chatPrivate = members.find((m) => m.id === chatUser);

      const name = chatPrivate.name;
      const image = chatPrivate.avatar;
      const initials = chatPrivate.initials;
      
      setChannel({ id: null, name, image, initials });
    } else setOpenProfile(null);
  }, [chatUser, members]);

  useEffect(() => {
    if (chatEl && unreadEl) {
      chatEl.scrollTop = (chatEl.scrollHeight - chatEl.clientHeight) + unreadEl.offsetTop - 86;
    }
  }, [chatEl, unreadEl]);

  useEffect(() => {
    const listener = () => {
      if (chatEl.scrollTop === 0) setLimit((l) => l + 20);
    };

    if (chatEl) chatEl.addEventListener('scroll', listener);

    return () => {
      if (chatEl) chatEl.removeEventListener('scroll', listener);
    };
  }, [chatEl]);

  useEffect(() => {
    if (chat && !messages) Message.index(chat.id, limit);
  }, [Message, chat, limit, messages]);

  useEffect(() => {
    if (chatEl && chat && channel && chat.id !== channel.id) {
      setLimit(20);
      setOpenProfile(null);
      chatEl.scrollTop = chatEl.scrollHeight - chatEl.clientHeight;
    }
  }, [chat, channel, chatEl]);
  
  const close = () => {
    setLimit(20);
    Message.clear();
    if (chat) Channel.closeChannel(member.id, chat.id);
    onClose();
  }

  return (
    <Drawer anchor="right" open={!!chat || !!chatUser} className={classes.drawer}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        className={classes.content}
        style={{ boxShadow: '0px 6px 24px 12px #2d2d2d', zIndex: 3 }}
      >
        <Grid container alignItems="center">
          <IconButton color="primary" onClick={close} style={{ margin: '0 8px 0 -8px' }}>
            <ArrowLeft />
          </IconButton>
          <Avatar className={classes.avatar} src={channel.image} alt={channel.name}>
            {channel.initials}
          </Avatar>
          <Typography className={classes.title}>{channel.name}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column-reverse"
        wrap="nowrap"
        className={classes.content}
        style={{ padding: '0 16px', height: 'calc(100% - 166px)', overflow: 'auto' }}
        ref={setChatEl}
      >
        {!!chat && !!messages
          ? messages.map((msg, i) => [
            <MessageBox channel={chat} key={msg.id} data={msg} openProfile={setOpenProfile} />,
            // msg.id === firstUnread && (
            //   <Grid key="unread" container justify="center" style={{ position: 'relative' }} ref={setUnreadEl}>
            //     <div className={classes.divider} />
            //     <div className={classes.dividerBox}>Não lidas</div>
            //   </Grid>
            // ),
            i === (messages.length - 1) && <div key="space" style={{ minHeight: 12, width: 12 }} />,
          ])
          : <Loading content={200} ready={(!!chat && !!messages) || !!chatUser} />}
      </Grid>
      <SenderBox channel={chat} chatUser={chatUser} newChat={newChat} />

      <UserProfile
        user={openProfile}
        onClose={() => setOpenProfile(null)}
        openChat={(id) => {
          Message.clear();
          openChat(id);
        }}
      />
    </Drawer>
  );
}

Chat.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    members: PropTypes.array,
  }),
  chatUser: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  chat: null,
  chatUser: null,
  messages: null,
};

export default Chat;
