import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { storage } from 'services/firebase';

// Material UI Components
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { X, Home, Folder, Image, Download } from 'react-feather';
import Loading from 'common/loading';
import styles from './style';

function EventForm() {
  const classes = styles();

  const history = useHistory();
  const { communityId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [path, setPath] = useState(`communities/${communityId}`);
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDocuments = async () => {
      setLoading(true);
      const pathRef = storage.ref(path);
      const files = await pathRef.listAll();

      const newDocuments = [];
      for (let i = 0; i < files.items.length; i++) {
        const file = files.items[i];
        const url = await pathRef.child(file.name).getDownloadURL();
        newDocuments.push({ name: file.name, url });
      }
      setDocuments(newDocuments);

      const newFolders = [];
      for (let i = 0; i < files.prefixes.length; i++) {
        const folder = files.prefixes[i];
        newFolders.push({ name: folder.name, path: folder.fullPath });
      }
      setFolders(newFolders);

      setLoading(false);
    };

    getDocuments();
  }, [path]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
      open
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>MATERIAIS DA GALÁXIA</span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column">
          {!loading ? (
            <>
              <Grid container alignItems="center" wrap="nowrap" className={classes.path}>
                <Breadcrumbs aria-label="breadcrumb">
                  {path.split('/').length === 2 ? (
                    <Typography color="textPrimary">
                      <Home size={18} style={{ marginBottom: -3 }} />
                    </Typography>
                  ) : path.split('/').slice(1).map((folder, i) => {
                    const url = path.split('/').slice(0, i + 2).join('/');
                    console.log(url);
                    if (i === 0) {
                      return (
                        <Link
                          color="primary"
                          onClick={() => setPath(`communities/${communityId}`)}
                          style={{ cursor: 'pointer' }}
                        >
                          <Home size={18} style={{ marginBottom: -3 }} />
                        </Link>
                      );
                    } else if (i === path.split('/').slice(1).length - 1) {
                      return <Typography color="textPrimary">{folder}</Typography>;
                    } else {
                      return (
                        <Link
                          color="primary"
                          onClick={() => setPath(url)}
                          style={{ cursor: 'pointer' }}
                        >
                          {folder}
                        </Link>
                      );
                    }
                  })}
                </Breadcrumbs>
              </Grid>
              <Grid container direction="column" style={{ flex: 1 }}>
                <Grid container>
                  {folders.map((folder) => (
                    <Grid
                      key={folder.path}
                      container
                      wrap="nowrap"
                      alignItems="center"
                      className={classes.folder}
                      onClick={() => setPath(folder.path)}
                    >
                      <Folder style={{ marginRight: 16 }} />
                      <Typography className={classes.folderName}>{folder.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container style={{ marginTop: 16 }}>
                  {documents.map((file) => (
                    <Grid
                      key={file.url}
                      container
                      wrap="nowrap"
                      alignItems="center"
                      justify="space-between"
                      className={classes.file}
                      onClick={() => window.open(file.url)}
                    >
                      <Grid container style={{ width: 'fit-content' }}>
                        <Image style={{ marginRight: 16 }} />
                        <Typography className={classes.folderName}>{file.name}</Typography>
                      </Grid>
                      <IconButton color="primary">
                        <Download size={18} />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
                {folders.length === 0 && documents.length === 0 && (
                  <Grid container alignItems="center" justify="center">
                    <Typography className={classes.noData}>
                      A pasta está vazia
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Loading ready={!loading} content={300} />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EventForm;
