/**
 * Types
 */
const Types = {
  LOAD_COMMUNITY: '@community/LOAD_COMMUNITY',
  LOAD_MEMBERS: '@community/LOAD_MEMBERS',
  LOAD_ARTICLES: '@community/LOAD_ARTICLES',
  LOAD_PLANS: '@community/LOAD_PLANS',
  LOAD_EVENTS: '@community/LOAD_EVENTS',
  LOAD_HOME_EVENTS: '@community/LOAD_HOME_EVENTS',
  CLEAR_COMMUNITY: '@community/CLEAR_COMMUNITY',
};

/**
 * Actions
 */
export const Actions = {
  loadCommunity: (current) => ({ type: Types.LOAD_COMMUNITY, payload: { current } }),
  loadMembers: (members) => ({ type: Types.LOAD_MEMBERS, payload: { members } }),
  loadArticles: (articles) => ({ type: Types.LOAD_ARTICLES, payload: { articles } }),
  loadPlans: (plans) => ({ type: Types.LOAD_PLANS, payload: { plans } }),
  loadEvents: (events) => ({ type: Types.LOAD_EVENTS, payload: { events } }),
  loadHomeEvents: (homeEvents) => ({ type: Types.LOAD_HOME_EVENTS, payload: { homeEvents } }),
  clearCommunity: () => ({ type: Types.CLEAR_COMMUNITY }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  members: null,
  articles: null,
  plans: null,
  events: null,
  homeEvents: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_COMMUNITY: {
      const { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_MEMBERS: {
      const { members } = action.payload;
      return { ...state, members };
    }
    case Types.LOAD_ARTICLES: {
      const { articles } = action.payload;
      return { ...state, articles };
    }
    case Types.LOAD_PLANS: {
      const { plans } = action.payload;
      return { ...state, plans };
    }
    case Types.LOAD_EVENTS: {
      const { events } = action.payload;
      return { ...state, events };
    }
    case Types.LOAD_HOME_EVENTS: {
      const { homeEvents } = action.payload;
      return { ...state, homeEvents };
    }
    case Types.CLEAR_COMMUNITY: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
