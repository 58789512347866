import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Material Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Redux Actions
import useMemberController from 'store/member/controller';

// Icons, Commons, Assets and Styles
import video from 'assets/auth-background.vid';
import PhoneMask from 'common/inputs-mask/phone';
import UploadAvatar from './avatar';
import styles from './style';

function ProfileCompleteForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const Member = useMemberController(dispatch);

  const { current: member } = useSelector((store) => store.member);

  const [scrollPos, setScrollPos] = useState(0);
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const content = document.getElementById('container');
    if (content) content.scrollLeft = scrollPos;
  }, [scrollPos]);

  const submitCompleteProfile = async () => {
    if (phone.trim().length < 14) setError(true);
    else {
      Member.update(member.id, { description, phone, interests: tags, needComplete: false });
      history.replace('/');
    }
  };

  return (
    <Grid container style={{ overflow: 'hidden', width: '100vw' }}>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>
      
      <div id="container" className={classes.container}>
        <Grid
          container
          wrap="nowrap"
          justify="center"
          alignItems="center"
          style={{ width: 'fit-content' }}
        >

          <Grid container className={classes.step} id="step1">
            <Grid container direction="column" justify="center" className={classes.stepContainer}>
              <Typography className={classes.title}>
                AGORA QUE JÁ ESTÁ À BORDO, PRECISO DE ALGUMAS INFORMAÇÕES PARA PASSAR AO COMANDATE
              </Typography>

              <Typography className={classes.question}>
                São três perguntas rápidas que vão influenciar na sua viagem pelas galáxias da hybri
              </Typography>
              
              <Grid container justify="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  className={classes.button}
                  onClick={() => setScrollPos(document.getElementById('step2').offsetLeft)}
                >
                  BORA!
                </Fab>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.step} id="step2">
            <Grid container direction="column" justify="center" className={classes.stepContainer}>
              <Typography className={classes.question}>
                Primeiro, me conte mais sobre você, terráqueo
              </Typography>

              <Grid container wrap="nowrap">
                <Grid item style={{ position: 'relative' }}>
                  <Avatar
                    alt={member.name}
                    src={member.avatar || null}
                    className={classes.avatar}
                  >
                    {member.initials}
                  </Avatar>
                  <UploadAvatar />
                </Grid>

                <TextField
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
                    inputProps: { style: { minHeight: 60 } },
                  }}
                />
              </Grid>
              
              <Grid container justify="center" style={{ marginTop: 32 }}>
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  className={classes.button}
                  onClick={() => setScrollPos(document.getElementById('step3').offsetLeft)}
                >
                  PRÓXIMA!
                </Fab>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.step} id="step3">
            <Grid container direction="column" justify="center" className={classes.stepContainer}>
              <Typography className={classes.question}>
                Agora preciso saber quando você é você mesmo? O que você gosta de fazer?
                O que te motiva?
              </Typography>

              <TextField
                placeholder="Dê enter para adicionar à uma nuvem de tags"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.stopPropagation();
                    setTags([...tags, tag]);
                    setTag('');
                  }
                }}
                variant="outlined"
                fullWidth
                InputProps={{
                  style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
                }}
              />

              {tags.length > 0 && (
                <Grid container justify="center" style={{ margin: '24px 0' }}>
                  {tags.map((tag, i) => (
                    <Chip
                      key={tag}
                      color="primary"
                      label={tag}
                      className={classes.tag}
                      onDelete={() => setTags((t) => t.filter((_, j) => j !== i))}
                    />
                  ))}
                </Grid>
              )}
              
              <Grid container direction="row-reverse" justify="center" style={{ marginTop: 32 }}>
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  className={classes.button}
                  onClick={() => setScrollPos(document.getElementById('step4').offsetLeft)}
                >
                  BORA PARA A ÚLTIMA PERGUNTA!
                </Fab>
                <Button
                  color="primary"
                  className={classes.buttonBack}
                  onClick={() => setScrollPos(document.getElementById('step2').offsetLeft)}
                >
                  VOLTAR
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.step} id="step4">
            <Grid container direction="column" justify="center" className={classes.stepContainer}>
              <Typography className={classes.question}>
                Por fim, só preciso do seu número de comunicação terrestre
              </Typography>

              <TextField
                type="phone"
                placeholder="Número do celular"
                error={error}
                value={phone}
                onChange={(e) => {
                  const { value } = e.target;
                  setError(value.trim().length < 14)
                  setPhone(e.target.value);
                }}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: PhoneMask,
                  style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
                }}
              />
              
              <Grid container direction="row-reverse" justify="center" style={{ marginTop: 32 }}>
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  className={classes.button}
                  onClick={submitCompleteProfile}
                >
                  AGORA SIM, VAMOS DECOLAR!
                </Fab>
                <Button
                  color="primary"
                  className={classes.buttonBack}
                  onClick={() => setScrollPos(document.getElementById('step3').offsetLeft)}
                >
                  VOLTAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </div>
    </Grid>
  );
}

export default ProfileCompleteForm;
