import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from '../reducer';

// import sendNotification from 'services/notification';

const Message = (dispatch, communityId) => ({
  index: (channelId, limit = 20) => {
    firestore.collection('communities').doc(communityId)
      .collection('channels').doc(channelId).collection('messages')
      .where('deletedAt', '==', null).orderBy('sendAt', 'desc').limit(limit)
      .onSnapshot((ref) => {
        const messages = ref.docs.map((msg) => ({ id: msg.id, ...msg.data() }));
        dispatch(Actions.loadMessages(messages));
      }, (error) => {
        console.log(error);
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (channelId, data) => {
    const now = new Date();

    return firestore.collection('communities').doc(communityId)
      .collection('channels').doc(channelId).collection('messages')
      .add({ ...data, sendAt: now, updatedAt: now, deletedAt: null })
      .then((doc) => {
        // if (data.unread) {
        //   const [firstName] = user.name.split(' ');
        //   let { content } = data;
        //   if (content === '') content = 'Enviou um arquivo';

        //   const notify = {
        //     title: channel.group ? channel.group.name : user.name,
        //     description: `${channel.group ? `${firstName}: ` : ''}${content}`,
        //   };

        //   data.unread.forEach((u) => sendNotification(u, notify));
        // }
        return doc.id;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: (channelId, id) => {
    return firestore.collection('communities').doc(communityId)
      .collection('channels').doc(channelId)
      .collection('messages').doc(id)
      .set({ deletedAt: new Date() }, { merge: true })
      .then(() => true)
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  clear: () => {
    dispatch(Actions.clearMessages());
  },
});

export default Message;
