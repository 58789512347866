import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

export default styled((props) => (
  <Tooltip
    enterTouchDelay={0}
    classes={{ popper: props.className, tooltip: props.title ? 'tooltip' : 'hide' }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))`
  & .tooltip {
    font-size: 14px;
    text-align: center;
    max-width: 200px;
    box-shadow: 1px 2px 20px 2px rgba(81,77,92,0.2);
  }

  & .hide {
    display: none;
  }
`;
