import React from 'react';
import { useSelector } from 'react-redux';
import {
  isSameDay, differenceInHours, differenceInMinutes, differenceInCalendarDays, format,
} from 'date-fns';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Assets
import { Image, Folder, FileText } from 'react-feather';
import Loading from 'common/loading';
import styles from './style';

function CommunityChannels({ openChat }) {
  const classes = styles();

  const { current: channels } = useSelector((store) => store.channel);
  const { current: member } = useSelector((store) => store.member);
  const { members } = useSelector((store) => store.community);
  
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.sectionTitle} style={{ marginBottom: 16 }}>
          CANAIS
        </Typography>

        {channels ? channels.filter((c) => !c.members).filter((_, i) => i < 5).map((channel) => {
          const { lastMsg, lastSee } = channel;
          let channelUnread = false;

          let date = '';
          let sender = '';
          let content = '';

          if (lastMsg) {
            content = lastMsg.content;
            const now = new Date();
            const sendAt = lastMsg.sendAt.toDate();
            
            if (lastSee) {
              channelUnread = sendAt > lastSee;
            } else channelUnread = true;

            if (isSameDay(now, sendAt)) {
              const diffHours = differenceInHours(now, sendAt);
              if (diffHours === 0) {
                const diffMinutes = differenceInMinutes(now, sendAt);
                if (diffMinutes === 0) date = 'Agora';
                else date = `Há ${diffMinutes} minuto${diffMinutes > 1 ? 's' : ''}`;
              } else date = `Há ${diffHours} hora${diffHours > 1 ? 's' : ''}`;
            } else {
              const diffDays = differenceInCalendarDays(now, sendAt);
              if (diffDays === 1) date = 'Ontem';
              else date = format(sendAt, 'dd/MM/yyyy');
            }

            if (lastMsg.sender === member.id) sender = 'Você: ';
            else {
              const sdr = members.find((u) => u.id === lastMsg.sender);
              if (sdr) sender = `${sdr.name.split(' ')[0]}: `;
            }

            if (lastMsg.attachments && lastMsg.attachments.length > 0) {
              const icons = [Image, Folder, FileText];
              const labels = ['Imagem', 'Pasta', 'Arquivo'];
              const extensions = [
                'jpg,jpeg,gif,raw,png,tiff,tif,svg',
                'zip,rar,tar',
                'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
              ];

              const [extension] = lastMsg.attachments[0].name.split('.').reverse();

              let Icon = FileText;
              let label = 'Arquivo';

              if (extension) {
                for (let i = 0; i < extensions.length; i += 1) {
                  if (extensions[i].indexOf(extension) >= 0) {
                    Icon = icons[i];
                    label = labels[i];
                  }
                }
              }

              content = (
                <span style={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                  <Icon size={14} style={{ margin: '1px 8px' }} />
                  {label}
                </span>
              );
            }
          }

          return (
            <Button key={channel.id} className={classes.channel} onClick={() => openChat(channel)}>
              <Grid container alignItems="center" wrap="nowrap">
                <Avatar src={channel.avatar} alt={channel.name} className={classes.channelAvatar}>
                  {channel.initials}
                </Avatar>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <Typography className={classes.channelName}>{channel.name}</Typography>
                  {lastMsg && (
                    <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                      <Typography className={classes.channelMsg}>
                        {sender}
                        {content}
                      </Typography>
                    </Grid>
                  )}
                </div>
                {channelUnread && (
                  <div className={classes.channelUnread} />
                )}
              </Grid>
            </Button>
          );
        }) : (
          <Loading ready={!!channels} content={200} />
        )}

        <Button fullWidth color="secondary" className={classes.btnMore} onClick={() => openChat(true)}>
          VER TODOS
        </Button>
      </CardContent>
    </Card>
  );
}

export default CommunityChannels;
