import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    padding: '12px 24px 24px',
  },
  planTitle: {
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  planBenefits: {
    padding: theme.spacing(2),
  },
  planPrice: {
    fontSize: 28,
    fontWeight: 600,
    color: theme.palette.success.main,
  },
}));
