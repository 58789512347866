import { createMuiTheme } from '@material-ui/core/styles';

const shadows = [];

for (let i = 1; i < 25; i += 1) {
  shadows.push(
    `${parseInt(1 + (i / 8), 10)}px `
    + `${1 + parseInt(4 + (i / 8), 10)}px `
    + `${24 + i}px 0 `
    + `rgba(0,0,0,${0.4 + parseInt(i / 8, 10) / 10})`,
  );
}

export default createMuiTheme({
  palette: {
    type: 'dark',
    common: {
      black: '#fff',
      grey: '#b1b1b1',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#2d2d2d',
      paper: '#2d2d2d',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    primary: {
      light: '#D6C4FF',
      main: '#c0a5ff',
      dark: '#9B78EE',
      contrastText: '#000',
    },
    // primary: {
    //   light: '#8560DC',
    //   main: '#5a33b5',
    //   dark: '#431C9E',
    //   contrastText: '#fff',
    // },
    secondary: {
      light: '#B7D9FF',
      main: '#8ec2ff',
      dark: '#4C90DF',
      contrastText: '#000',
    },
    // secondary: {
    //   light: '#69ACFE',
    //   main: '#4291ef',
    //   dark: '#2777D9',
    //   contrastText: '#fff',
    // },
    tertiary: {
      light: '#798bc7',
      main: '#586eba',
      dark: '#3d4d82',
      contrastText: '#fff',
    },
    success: {
      light: '#6fc57c',
      main: '#4bb75c',
      dark: '#348040',
      contrastText: '#313131',
    },
    warning: {
      light: '#f7ab79',
      main: '#f59658',
      dark: '#ab693d',
      contrastText: '#313131',
    },
    waiting: {
      light: '#ebdd60',
      main: '#e6d539',
      dark: '#a19527',
      contrastText: '#313131',
    },
    error: {
      light: '#e34b51',
      main: '#dc1f26',
      dark: '#9a151a',
      contrastText: '#fff',
    },
    // contrastThreshold: 2.6,
  },
  shadows: ['none', ...shadows],
  typography: {
    useNextVariants: true,
  },
  spacing: 16,
});
