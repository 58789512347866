/**
 * Types
 */
const Types = {
  LOAD: '@member/LOAD',
  LOAD_COMMUNITY: '@member/LOAD_COMMUNITY',
  CLEAR: '@member/CLEAR',
};

/**
 * Actions
 */
export const Actions = {
  load: (current) => ({ type: Types.LOAD, payload: { current } }),
  loadCommunity: (community) => ({ type: Types.LOAD_COMMUNITY, payload: { community } }),
  clear: () => ({ type: Types.CLEAR }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  communities: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD: {
      const { current } = action.payload;
      return { ...state, current, isLoadedMember: true };
    }
    case Types.LOAD_COMMUNITY: {
      const { community } = action.payload;
      let { communities } = state;
      
      const existCommunity = communities && communities.find((c) => c.id === community.id);
      if (existCommunity) {
        const index = communities.indexOf(existCommunity);
        communities[index] = community;
      } else {
        if (communities) communities.push(community);
        communities = [community];
      }

      return { ...state, communities };
    }
    case Types.CLEAR: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
