import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { renderRoutes } from 'routes/utils';
import Loading from 'common/loading';

// Controllers
import useAuthController from 'store/auth/controller';
import useCommunityController from 'store/community/controller';

function PrivateRoute({ component: Component, path, exact, childrenRoutes }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Controller
  const Auth = useAuthController(dispatch);
  const Community = useCommunityController(dispatch);

  const { isAuth, isLoaded: isLoadedAuth } = useSelector((state) => state.auth);
  const { current: member, communities } = useSelector((state) => state.member);
  const { current: community } = useSelector((state) => state.community);

  useEffect(() => {
    if (!isLoadedAuth && !member) Auth.check();
  }, [Auth, isLoadedAuth, member]);

  useEffect(() => {
    if (member && !communities) Community.index(member.communities);
  }, [Community, communities, member]);

  const isLoaded =  (member && member.communities.length === 0)
    || (isLoadedAuth && !isAuth) || !!communities;

  const currentPath = history.location.pathname;

  return (
    <>
      <Loading ready={isLoaded} />
      {isLoaded && (
        <Route
          path={path}
          exact={exact}
          render={(props) => {
            const { communityId } = props.match.params;
            if (communityId && !community) Community.show(communityId);

            if (member && !member.needComplete) {
              if (currentPath === '/profile/complete') {
                return <Redirect to="/" />;
              } else if (member.communities.length === 0 && currentPath !== '/communities') {
                return <Redirect to="/communities" />;
              }
            } else if (currentPath !== '/profile/complete') {
              return <Redirect to="/profile/complete" />;
            }
            

            return isAuth
              ? <Component />
              : <Redirect
                  to={`/auth/sign-in${currentPath !== '/' ? `?redirectUrl=${currentPath}` : ''}`}
                />;
          }}
        />
      )}
      {isLoaded && <Switch>{renderRoutes(childrenRoutes)}</Switch>}
    </>
  );
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  childrenRoutes: PropTypes.arrayOf(PropTypes.object),
};

PrivateRoute.defaultProps = {
  exact: false,
  childrenRoutes: [],
};

export default PrivateRoute;
