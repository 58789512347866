import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const FIXED_PHONE = [
  '(',
  /[1-9]/,
  /[1-9]/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const CELLPHONE = [
  '(',
  /[1-9]/,
  /[1-9]/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

function mask(userInput) {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;

  if (numbers) numberLength = numbers.join('').length;

  if (numberLength > 10) return CELLPHONE;

  return FIXED_PHONE;
}

function PhoneMasked({ inputRef, ...props }) {
  const { value } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={mask(value)}
      placeholderChar={'\u2000'}
    />
  );
}

PhoneMasked.propTypes = {
  inputRef: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default PhoneMasked;
