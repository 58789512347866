import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  isSameDay, differenceInHours, differenceInMinutes, differenceInCalendarDays, format,
} from 'date-fns';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useChannelController from 'store/channel/controller';
import useMessageController from 'store/channel/message/controller';
import useMemberController from 'store/member/controller';

// Icons and Styles
import { ArrowLeft, Image, Folder, FileText, ArrowUpLeft, X } from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import Conversation from './conversation';
import styles from './style';

function Chat({ open, onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { communityId } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Channel = useChannelController(dispatch, communityId);
  const Message = useMessageController(dispatch, communityId);
  const Member = useMemberController(dispatch);

  const { current: member } = useSelector((store) => store.member);
  const { current: channels } = useSelector((store) => store.channel);
  const { members } = useSelector((store) => store.community);

  const [selectedChannel, setSelectedChannel] = useState(null);
  const [newPrivateChannel, setNewPrivateChannel] = useState(null);

  useEffect(() => {
    if (!channels) Channel.index(member.id);
  }, [Channel, channels, dispatch, member.id]);

  useEffect(() => {
    if (!members) Member.index(communityId);
  }, [Member, members, dispatch, communityId]);

  useEffect(() => {
    if (open && open.id) setSelectedChannel(open);
    if (open && typeof open === 'string') {
      const existChat = channels && channels.find((c) => c.members && c.members.indexOf(open) >= 0);
      if (existChat) setSelectedChannel(existChat);
      else setNewPrivateChannel(open);
    }
  }, [channels, open]);

  const close = () => {
    setSelectedChannel(null);
    onClose();
  };

  const isLoaded = channels && members;

  return (
    <>
      <Drawer anchor="right" open={!!open} onClose={close}>
        <Grid container wrap="nowrap" style={{ overflow: 'hidden', height: '100%' }}>
          <Grid container direction="column" alignItems="center" className={classes.sidebar}>
            <IconButton color="secondary" onClick={close} className={classes.add}>
              <X />
            </IconButton>
            {isLoaded && channels
              .filter((c) => !c.members)
              .sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate())
              .map((channel) => {
                const name = channel.name;
                const image = channel.avatar;
                const initials = channel.initials;

                return (
                  <div key={channel.id} className={classes.channel}>
                    <Tooltip title={name} placement="right">
                      <Avatar
                        src={image}
                        alt={name}
                        className={classes.avatar}
                        onClick={() => {
                          setSelectedChannel(channel);
                          Message.clear();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {initials}
                      </Avatar>
                    </Tooltip>
                  </div>
                );
              })}
          </Grid>
          <Grid container direction="column">
            <Grid container justify="space-between" alignItems="center" className={classes.toolbar}>
              {mobile ? (
                <IconButton color="primary" onClick={close}>
                  <ArrowLeft />
                </IconButton>
              ) : <div />}
              <Typography className={classes.title}>CONVERSAS</Typography>
              <div />
            </Grid>
            <Grid container className={classes.content}>
              <List style={{ width: '100%' }}>
                {isLoaded ? channels
                  .sort((a, b) => {
                    if (b.lastMsg && a.lastMsg) {
                      return b.lastMsg.sendAt.toDate() - a.lastMsg.sendAt.toDate();
                    }
                    if (a.lastMsg) return 1;
                    return 1;
                  })
                  .map((channel) => {
                    const { lastMsg, lastSee } = channel;
                    let channelUnread = false;

                    let channelPrivate = channel.members && channel.members.find((m) => m !== member.id);
                    if (channelPrivate) channelPrivate = members.find((m) => m.id === channelPrivate);

                    const name = channelPrivate ? channelPrivate.name : channel.name;
                    const image = channelPrivate ? channelPrivate.avatar : channel.avatar;
                    const initials = channelPrivate ? channelPrivate.initials : channel.initials;

                    let date = '';
                    let sender = '';
                    let content = '';

                    if (lastMsg) {
                      content = lastMsg.content;
                      const now = new Date();
                      const sendAt = lastMsg.sendAt.toDate();
                      
                      if (lastSee) {
                        channelUnread = sendAt > lastSee;
                      } else channelUnread = true;

                      if (isSameDay(now, sendAt)) {
                        const diffHours = differenceInHours(now, sendAt);
                        if (diffHours === 0) {
                          const diffMinutes = differenceInMinutes(now, sendAt);
                          if (diffMinutes === 0) date = 'Agora';
                          else date = `Há ${diffMinutes} minuto${diffMinutes > 1 ? 's' : ''}`;
                        } else date = `Há ${diffHours} hora${diffHours > 1 ? 's' : ''}`;
                      } else {
                        const diffDays = differenceInCalendarDays(now, sendAt);
                        if (diffDays === 1) date = 'Ontem';
                        else date = format(sendAt, 'dd/MM/yyyy');
                      }

                      if (lastMsg.sender === member.id) sender = 'Você: ';
                      else {
                        const sdr = members.find((u) => u.id === lastMsg.sender);
                        if (sdr) sender = `${sdr.name.split(' ')[0]}: `;
                      }

                      if (lastMsg.attachments && lastMsg.attachments.length > 0) {
                        const icons = [Image, Folder, FileText];
                        const labels = ['Imagem', 'Pasta', 'Arquivo'];
                        const extensions = [
                          'jpg,jpeg,gif,raw,png,tiff,tif,svg',
                          'zip,rar,tar',
                          'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
                        ];

                        const [extension] = lastMsg.attachments[0].name.split('.').reverse();

                        let Icon = FileText;
                        let label = 'Arquivo';

                        if (extension) {
                          for (let i = 0; i < extensions.length; i += 1) {
                            if (extensions[i].indexOf(extension) >= 0) {
                              Icon = icons[i];
                              label = labels[i];
                            }
                          }
                        }

                        content = (
                          <span style={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                            <Icon size={14} style={{ margin: '1px 8px' }} />
                            {label}
                          </span>
                        );
                      }
                    }

                    return [
                      <Divider component="li" key={`divider-${channel.id}`} />,
                      <ListItem
                        button
                        key={channel.id}
                        style={{ padding: '8px 24px' }}
                        onClick={() => setSelectedChannel(channel)}
                      >
                        <ListItemAvatar>
                          <Avatar src={image} alt={name} className={classes.avatar}>
                            {initials}
                          </Avatar>
                        </ListItemAvatar>
                        <Grid container direction="column" style={{ overflow: 'hidden' }}>
                          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                            <Typography className={classes.chatName}>{name}</Typography>
                            <Typography className={classes.date}>{date}</Typography>
                          </Grid>
                          {lastMsg && (
                            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                              <Typography className={classes.message}>
                                {sender}
                                {content}
                              </Typography>
                              {channelUnread && (
                                <Chip color="secondary" className={classes.chip} />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>,
                    ];
                  }) : (
                    <Loading ready={!!isLoaded} content={400} />
                  )}
                {isLoaded && <Divider component="li" />}
                {isLoaded && channels.length === 0 && (
                  <Typography style={{ color: '#777', fontSize: 18, padding: 24 }}>
                    <ArrowUpLeft style={{ marginRight: 16, color: '#4eb1d4' }} />
                    Comece criando os canais de comunicação com seu time
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>

      <Conversation
        chat={selectedChannel}
        chatUser={newPrivateChannel}
        onClose={() => {
          setSelectedChannel(null);
          setNewPrivateChannel(null);
        }}
        newChat={(chat) => {
          setSelectedChannel(chat);
          setNewPrivateChannel(null);
        }}
        openChat={(id) => {
          const chat = channels.find(
            (c) => c.members && c.members.indexOf(id) >= 0,
          );
          if (chat) {
            setSelectedChannel(chat);
            setNewPrivateChannel(null);
          } else {
            setNewPrivateChannel(id);
            setSelectedChannel(null);
          }
        }}
      />
    </>
  );
}

Chat.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chat;
