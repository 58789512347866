import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { reducer as alert } from './alert/reducer';
import { reducer as article } from './article/reducer';
import { reducer as auth } from './auth/reducer';
import { reducer as channel } from './channel/reducer';
import { reducer as community } from './community/reducer';
import { reducer as company } from './company/reducer';
import { reducer as event } from './event/reducer';
import { reducer as form } from './form/reducer';
import { reducer as member } from './member/reducer';

const reducers = combineReducers({
  alert, article, auth, channel, community, company, event, form, member,
});

export default createStore(reducers, applyMiddleware(thunk));
