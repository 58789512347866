/**
 * Types
 */
const Types = {
  LOAD_CHANNELS: '@channel/LOAD_CHANNELS',
  LOAD_LAST_MESSAGE: '@channel/LOAD_LAST_MESSAGE',
  LOAD_LAST_SEE: '@channel/LOAD_LAST_SEE',
  LOAD_MESSAGES: '@channel/LOAD_MESSAGES',
  CLEAR_MESSAGES: '@channel/CLEAR_MESSAGES',
  CLEAR_CHANNELS: '@channel/CLEAR_CHANNELS',
};

/**
 * Actions
 */
export const Actions = {
  loadChannels: (current) => ({ type: Types.LOAD_CHANNELS, payload: { current } }),
  loadLastMessage: (data) => ({ type: Types.LOAD_LAST_MESSAGE, payload: { data } }),
  loadLastSee: (data) => ({ type: Types.LOAD_LAST_SEE, payload: { data } }),
  loadMessages: (messages) => ({ type: Types.LOAD_MESSAGES, payload: { messages } }),
  clearMessages: () => ({ type: Types.CLEAR_MESSAGES }),
  clearChannels: () => ({ type: Types.CLEAR_CHANNELS }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  messages: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_CHANNELS: {
      let { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_LAST_MESSAGE: {
      const { current: channels } = state;
      const { data } = action.payload;
      
      const channel = channels.find((c) => c.id === data.channel);
      const index = channels.indexOf(channel);
      if (index >= 0) channels[index].lastMsg = data.lastMsg;

      return { ...state, current: channels };
    }
    case Types.LOAD_LAST_SEE: {
      const { current: channels } = state;
      const { data } = action.payload;
      
      const channel = channels.find((c) => c.id === data.channel);
      const index = channels.indexOf(channel);
      if (index >= 0) channels[index].lastSee = data.lastSee;

      return { ...state, current: channels };
    }
    case Types.LOAD_MESSAGES: {
      let { messages } = action.payload;
      return { ...state, messages };
    }
    case Types.CLEAR_CHANNELS: {
      return INITIAL_STATE;
    }
    case Types.CLEAR_MESSAGES: {
      return { ...state, messages: null };
    }
    default: {
      return state;
    }
  }
};
