import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

// Assets
import { Info, Star, Folder, MessageCircle } from 'react-feather';
import video from 'assets/auth-background.vid';
import logo from 'assets/logo.png';
import Tooltip from 'common/tooltip';
import styles from './style';
import Chat from './chat';

// Components
import CommunityInfos from './components/community-infos';
import Events from './components/events';
import Contents from './components/contents';
import Alerts from './components/alerts';
import Channels from './components/channels';

function Community() {
  const classes = styles();
  
  const history = useHistory();
  const { communityId } = useParams();

  const { current: community } = useSelector((store) => store.community);

  const container = useRef(null);
  const header = useRef(null);
  const title = useRef(null);
  const cover = useRef(null);

  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const line1 = useRef(null);
  const line2 = useRef(null);

  const [openChat, setOpenChat] = useState(false);

  // useEffect(() => {
  //   if (!articles) Article.index();
  //   else console.log(articles);
  // }, [Article, articles])

  useEffect(() => {
    if (container.current && header.current && title.current && cover.current) {
      container.current.addEventListener('scroll', () => {
        const scrollY = container.current.scrollTop;
        const height = cover.current.offsetHeight;
        let opacity = (scrollY - height / 2) / (height / 2);
        if (opacity < 0) opacity = 0;
        if (opacity > 1) opacity = 1;
        
        header.current.style.opacity = opacity;
        title.current.style.opacity = opacity;
      });
    }
  }, [container, header]);

  useEffect(() => {
    if (btn1.current && btn2.current && btn3.current && line1.current && line2.current) {
      function adjustLine(from, to, line) {
        let fT = from.offsetTop + from.offsetHeight/2;
        let tT = to.offsetTop + to.offsetHeight/2;
        let fL = from.offsetLeft + from.offsetWidth/2;
        let tL = to.offsetLeft + to.offsetWidth/2;
        
        let CA = Math.abs(tT - fT);
        let CO = Math.abs(tL - fL);
        let H = Math.sqrt(CA * CA + CO * CO);
        let ANG = 180 / Math.PI * Math.acos(CA / H);

        let top = 0;
        let left = 0;
      
        if (tT > fT) {
          top = (tT-fT)/2 + fT;
        } else {
          top = (fT-tT)/2 + tT;
        }

        if (tL > fL) {
          left = (tL-fL)/2 + fL;
        } else {
          left = (fL-tL)/2 + tL;
        }
      
        if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
          ANG *= -1;
        }

        top -= H/2;
      
        line.style["-webkit-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-moz-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-ms-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-o-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-transform"] = 'rotate('+ ANG +'deg)';
        line.style.top = `${top}px`;
        line.style.left = `${left}px`;
        line.style.height = H + 'px';
      }

      adjustLine(btn1.current, btn2.current, line1.current);
      adjustLine(btn2.current, btn3.current, line2.current);
    }
  }, []);

  
  
  
  return (
    <Grid container className={classes.mainContainer} ref={container}>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>
      <div className={classes.filter} />

      <AppBar position="fixed" className={classes.header} ref={header}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.toolbarTitle} ref={title}>
            {community && community.idealize.name}
          </Typography>
          <img src={logo} alt="Logo hybri" className={classes.logo} />
          <Avatar className={classes.userAvatar}>GO</Avatar>
        </Toolbar>
      </AppBar>

      <Grid container alignItems="flex-start" style={{ zIndex: 10 }}>
        <Grid container item sm={12} md={7} lg={8} direction="column" style={{ padding: '64px 32px 32px 64px' }}>
          <Grid
            container
            alignItems="flex-end"
            ref={cover}
            className={classes.cover}
            // style={community ? { backgroundImage: `url(${community.idealize.cover || defaultCover})` } : {}}
          >
            <Grid container justify="space-around" className={classes.shortcutsContainer}>
              <Tooltip TransitionComponent={Zoom} title="Sobre">
                <IconButton
                  color="secondary"
                  className={classes.shortcuts}
                  onClick={() => history.push(`/communities/${communityId}/about`)}
                  style={{ marginTop: 96 }}
                  ref={btn1}
                >
                  <Info size={30} />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Benefícios">
                <IconButton
                  color="secondary"
                  className={classes.shortcuts}
                  onClick={() => history.push(`/communities/${communityId}/benefits`)}
                  style={{ marginBottom: 96 }}
                  ref={btn2}
                >
                  <Star size={30} />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Materiais">
                <IconButton
                  color="secondary"
                  className={classes.shortcuts}
                  onClick={() => history.push(`/communities/${communityId}/materials`)}
                  style={{ marginTop: 140 }}
                  ref={btn3}
                >
                  <Folder size={30} />
                </IconButton>
              </Tooltip>
              <div className={classes.lineShortcut} ref={line1} />
              <div className={classes.lineShortcut} ref={line2} />
            </Grid>

            <CommunityInfos />
          </Grid>

          
          {/* SHORTCUTS */}
          {/* <Grid container style={{ margin: '32px -16px 0', width: 'calc(100% + 32px)' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => history.push(`/communities/${communityId}/about`)}
              className={classes.shortcuts}
            >
              <Info size={18} />
              SOBRE
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => history.push(`/communities/${communityId}/benefits`)}
              className={classes.shortcuts}
            >
              <Star size={18} />
              BENEFÍCIOS
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => history.push(`/communities/${communityId}/materials`)}
              className={classes.shortcuts}
            >
              <Folder size={18} />
              MATERIAIS
            </Button>
          </Grid> */}

          <Events />
        
          <Contents />
        </Grid>
        
        <Grid container item sm={12} md={5} lg={4} style={{ paddingRight: 32 }}>
          <Alerts />
          
          <Channels openChat={setOpenChat} />

          {/* LOG ACTIVIES */}
          {/* <Card className={classes.card} style={{ marginTop: 0 }}>
            <CardContent>
              <Typography className={classes.sectionTitle} style={{ marginBottom: 16 }}>
                ÚLTIMAS ATIVIDADES
              </Typography>

              <Button className={classes.channel}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.cardAvatar}>CH</Avatar>
                  <Typography className={classes.logMsg}>
                    {'Temos mais um encontro marcado para o dia {{dia}} no evento {{nome_evento}}!'}
                  </Typography>
                </Grid>
              </Button>
              
              <Button className={classes.channel}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.cardAvatar}>CH</Avatar>
                  <Typography className={classes.logMsg}>
                    {'Gustavo entrou na {{ comunidade }}! Dê as boas vindas para ele.'}
                  </Typography>
                </Grid>
              </Button>

              <Button className={classes.channel}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.cardAvatar}>CH</Avatar>
                  <Typography className={classes.logMsg}>
                    {'Alexandre comentou no artigo {{nome_artigo}}.'}
                  </Typography>
                </Grid>
              </Button>
              
              <Button className={classes.channel}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.cardAvatar}>CH</Avatar>
                  <Typography className={classes.logMsg}>
                    {'Confira aqui o mais novo canal no chat {{nome_artigo}}.'}
                  </Typography>
                </Grid>
              </Button>
              
              <Button className={classes.channel}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.cardAvatar}>CH</Avatar>
                  <Typography className={classes.logMsg}>
                    {'Amanhã tem o evento {{nome_evento}}, você não vai perder né?'}
                  </Typography>
                </Grid>
              </Button>

              <Button fullWidth color="secondary" className={classes.btnMore}>
                VER TODOS
              </Button>
            </CardContent>
          </Card> */}
        </Grid>
      </Grid>

      <Fab
        color="secondary"
        className={classes.chatFab}
        onClick={() => setOpenChat(true)}
      >
        <Badge color="primary" classes={{ badge: classes.badge }}>
          <MessageCircle />
        </Badge>
      </Fab>
      <Chat open={openChat} onClose={() => setOpenChat(false)} />
    </Grid>
  );
}

export default Community;
