import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    padding: '12px 24px 24px',
    lineHeight: 1.5,
    color: theme.palette.common.grey,
    '& h1, h2, h3': {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(),
    },
    '& p': {
      marginBottom: theme.spacing(),
    },
  },
  label: {
    marginBottom: '2px !important',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  value: {
    color: theme.palette.common.black,
  },
}));
