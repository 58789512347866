/**
 * Types
 */
const Types = {
  LOAD_ARTICLE: '@articles/LOAD_ARTICLE',
  LOAD_LIKE: '@articles/LOAD_LIKE',
  LOAD_COMMENTS: '@articles/LOAD_COMMENTS',
  CLEAR_ARTICLE: '@articles/CLEAR_ARTICLE',
};

/**
 * Actions
 */
export const Actions = {
  loadArticle: (current) => ({ type: Types.LOAD_ARTICLE, payload: { current } }),
  loadLike: (like) => ({ type: Types.LOAD_LIKE, payload: { like } }),
  loadComments: (comments) => ({ type: Types.LOAD_COMMENTS, payload: { comments } }),
  clearArticle: () => ({ type: Types.CLEAR_ARTICLE }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  like: null,
  comments: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_ARTICLE: {
      let { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_LIKE: {
      const { like } = action.payload;
      return { ...state, like };
    }
    case Types.LOAD_COMMENTS: {
      const { comments } = action.payload;
      return { ...state, comments };
    }
    case Types.CLEAR_ARTICLE: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
