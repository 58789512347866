import React from 'react';

// Materia UI Components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Utils
import makeUrl from './utils';
import styles from './style';

function AddToCalendar({ open, onClose, event }) {
  const classes = styles();
  const urls = makeUrl(event);

  return (
    <Menu
      open={!!open}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      className={classes.menu}
    >
      <a href={urls.google} target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={onClose}>Google Calendar</MenuItem>
      </a>
      <a download={event.name} href={urls.ics} target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={onClose}>iCalendar</MenuItem>
      </a>
      <a href={urls.yahoo} target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={onClose}>Yahoo!</MenuItem>
      </a>
      <a download={event.name} href={urls.ics} target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={onClose}>Outlook</MenuItem>
      </a>
      <a href={urls.outlook} target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={onClose}>Outlook Web</MenuItem>
      </a>
    </Menu>
  );
}

export default AddToCalendar;
