import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useCompanyController from 'store/company/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Loading from 'common/loading';
import styles from './style';

function EventForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Company = useCompanyController(dispatch);

  const { current: community } = useSelector((store) => store.community);
  const { current: company } = useSelector((store) => store.company);

  useEffect(() => {
    if (!company && community) Company.show(community.company);
  }, [Company, community, company]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      scroll="body"
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span style={{ textTransform: 'uppercase' }}>
            {`UM POUCO MAIS SOBRE ${community && community.idealize.name}`}
          </span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {community && company ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: community.about }} />
            <Typography className={classes.label}>EMPRESA PROPRIETÁRIA</Typography>
            <Typography className={classes.value}>{company.name}</Typography>
          </>
        ) : (
          <Loading ready={!!community && !!company} content={300} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EventForm;
