import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from '../reducer';

const Like = (dispatch, communityId, articleId) => ({
  index: async (limit = 25) => {
    firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('likes').orderBy('createdAt', 'desc').limit(limit)
      .onSnapshot(async (snapshot) => {
        const likes = [];
        
        for (let i = 0; i < snapshot.docs.length; i++) {
          const doc = snapshot.docs[i];
          const member = await firestore.collection('members').doc(doc.id).get()
            .then((snap) => snap.data());
          likes.push({ id: doc.id, ...member });
        }
        
        dispatch(Actions.loadArticles(likes));
      });
  },

  show: (memberId) => {
    firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('likes').doc(memberId)
      .onSnapshot((snapshot) => {
        dispatch(Actions.loadLike(snapshot.exists));
      });
  },

  store: async (memberId) => {
    const likesRef = firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('likes');

    return likesRef.doc(memberId).set({ like: true, createdAt: new Date() })
      .then(async () => {
        const likes = await likesRef.get();

        await firestore.collection('communities').doc(communityId)
          .collection('articles').doc(articleId)
          .set({ likes: likes.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: async (memberId) => {
    const likesRef = firestore.collection('communities').doc(communityId)
      .collection('articles').doc(articleId)
      .collection('likes');

    return likesRef.doc(memberId).delete()
      .then(async () => {
        const likes = await likesRef.get();

        await firestore.collection('communities').doc(communityId)
          .collection('articles').doc(articleId)
          .set({ likes: likes.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },
});

export default Like;
