import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    margin: '0 auto',
    padding: `0 ${theme.spacing()}px`,
  },
  logo: {
    display: 'block',
    height: '6rem',
    margin: `${theme.spacing(2)}px auto`,
    zIndex: 10,
  },
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  title: {
    position: 'relative',
    zIndex: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 24,
    textShadow: '0 0 30px rgba(255, 255, 255)',
    margin: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  passwordInput: {
    width: '100%',
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    width: '100%',
    zIndex: 10,
    margin: `${theme.spacing()}px 0`,
  },
  iconError: {
    opacity: 0.9,
    marginRight: theme.spacing(),
    minWidth: 20,
  },
  resetPassword: {
    float: 'right',
    padding: '12px 0',
  },

  // Community
  community: {
    width: '80%',
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '50vh',
    // opacity: 0.8,
    zIndex: 10,
    borderRadius: 8,
    boxShadow: '1px 4px 40px rgba(255, 255, 255, 0.4)',
  },
  communityInfos: {
    borderRadius: 8,
    padding: theme.spacing(2),
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)',
  },
  inveted: {
    background: theme.palette.secondary.main,
    padding: '8px 16px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  communityAvatar: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: `6px solid ${theme.palette.secondary.main}`,
    width: 120,
    height: 120,
    fontSize: 45,
    marginRight: theme.spacing(2),
  },
  communityName: {
    color: theme.palette.common.black,
    fontSize: 32,
    fontWeight: 600,
  },
  communityDesc: {
    color: theme.palette.common.grey,
    marginTop: theme.spacing(),
  },
  memberAvatar: {
    background: theme.palette.primary.main,
    marginRight: -8,
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    '&:last-child': { margin: 0 },
  },
}));
