import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isSameDay, format } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controller
import eventController from 'store/event/controller';

// Assets
import { Radio, ArrowRight, Bookmark } from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import AddToCalendar from 'common/add-to-calendar';
import styles from './style';

function CommunityEvents() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { communityId } = useParams();

  const { current: community, homeEvents: events } = useSelector((store) => store.community);

  const [saveEvent, setSaveEvent] = useState(null);

  useEffect(() => {
    if (!events && community) {
      const Event = eventController(dispatch, community.company);
      Event.indexHome();
    }
  }, [community, dispatch, events]);
  
  return (
    <>
      {/* LIVE EVENT */}
      <Grid container direction="column" justify="space-between" className={classes.liveEvent}>
        <Grid container alignItems="center" className={classes.liveStatus}>
          <Radio />
          ACONTECENDO AGORA
        </Grid>
        <Grid container alignItems="flex-end" justify="space-between" className={classes.liveInfo}>
          Lançamento Hybri \o/
          <Button variant="outlined" onClick={() => {}} className={classes.btnLive}>
            PARTICIPAR
          </Button>
        </Grid>
      </Grid>

      {/* NEXT EVENTS */}
      <Grid container style={{ marginTop: 96 }}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Typography className={classes.sectionTitle}>PRÓXIMOS EVENTOS</Typography>
          <Button
            color="secondary"
            variant="outlined"
            style={{ fontSize: 16 }}
            className={classes.button}
            onClick={() => history.push(`/communities/${communityId}/events`)}
          >
            VER CALENDÁRIO
            <ArrowRight size={18} style={{ marginLeft: 16 }} />
          </Button>
        </Grid>

        <Grid container wrap="nowrap" style={{ marginTop: 32 }}>
          {events ? events.map((event) => {
            let date = '';

            const startAt = event.dates.start.toDate();
            const endAt = event.dates.end.toDate();

            if (isSameDay(startAt, endAt)) {
              date = format(startAt, "dd/MM/yyyy' das 'HH:mm")
              date += format(endAt, "' às 'HH:mm")
            } else {
              date = format(startAt, "dd/MM/yyyy' até '")
              date += format(endAt, 'dd/MM/yyyy')
            }

            const delay = 4 * Math.random();

            return (
              <Grid
                item
                key={event.id}
                xs={12} sm={6} md={4}
                className={classes.nextEvent}
                style={{
                  backgroundImage: `url(${event.cover || 'https://hybri.io/img/galaxy-cover-2.jpg'})`,
                  animationDelay: `${delay}s`,
                }}
                onClick={() => history.push(`/communities/${communityId}/events/${event.id}`)}
              >
                <Grid
                  container
                  wrap="nowrap"
                  direction="column"
                  justify="space-between"
                  className={classes.eventoInfoContainer}
                >
                  <Grid container justify="flex-end">
                    <Tooltip title="Salvar na minha agenda" placement="left">
                      <span>
                        <Bookmark
                          className={classes.eventSave}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSaveEvent(e.currentTarget);
                          }}
                        />
                        <AddToCalendar
                          event={{
                            name: event.name,
                            details: event.creating ? event.creating.idealization.description : '',
                            location: '',
                            startsAt: format(event.dates.start.toDate(), "yyyy'-'MM'-'dd'T'HH':'mm':'ssXXX"),
                            endsAt: format(event.dates.end.toDate(), "yyyy'-'MM'-'dd'T'HH':'mm':'ssXXX"),
                          }}
                          open={saveEvent}
                          onClose={(e) => {
                            e.stopPropagation();
                            setSaveEvent(null);
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <div>
                    <Typography className={classes.eventName}>{event.name}</Typography>
                    <Typography style={{ fontSize: 13 }}>{date}</Typography>
                  </div>
                </Grid>
              </Grid>
            );
          }) : (
            <Loading ready={!!events} content={150} />
          )}
          {events && events.length === 0 && (
            <Grid container alignItems="center" justify="center" style={{ height: 150 }}>
              <Typography className={classes.noData}>
                Não há eventos futuros na comunidade
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CommunityEvents;
