import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  subMonths, addMonths, isSameDay, addDays, startOfMonth, lastDayOfMonth, subDays, format,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controller
import eventController from 'store/event/controller';

// Icons, Commons and Styles
import { X, ChevronLeft, ChevronRight } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import styles from './style';

function EventForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { communityId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  // const Event = useEventController(dispatch, communityId)

  const { current: community, events } = useSelector((store) => store.community);

  const [baseDay, setBaseDay] = useState(new Date());

  useEffect(() => {
    if (community) {
      const Event = eventController(dispatch, community.company);
      Event.index(baseDay.getMonth() + 1, baseDay.getFullYear());
    }
  }, [baseDay, community, dispatch]);

  const getEvents = (date) => events.filter((event) => {
    const startAt = event.dates.start.toDate();
    const endAt = event.dates.end.toDate();

    return isSameDay(startAt, date) || isSameDay(endAt, date)
      || (date > startAt && date < endAt);
  });

  const handlePrev = () => setBaseDay(subMonths(baseDay, 1));

  const handleNext = () => setBaseDay(addMonths(baseDay, 1));

  const Calendar = () => {
    const firstDay = startOfMonth(baseDay);
    const lastDay = lastDayOfMonth(baseDay);
    const lastDayWeek = lastDay.getDay();
    const lastDayLastMonth = lastDayOfMonth(subMonths(baseDay, 1));

    const days = [];

    for (let i = firstDay.getDay() - 1; i >= 0; i -= 1) {
      const date = subDays(lastDayLastMonth, i);
      days.push({ date, disabled: true, events: getEvents(date) });
    }

    for (let i = 0; i < lastDay.getDate(); i += 1) {
      const date = addDays(firstDay, i);
      days.push({ date, events: getEvents(date), today: isSameDay(new Date(), date) });
    }

    if (lastDayWeek < 6) {
      for (let i = 1; i <= 6 - lastDayWeek; i += 1) {
        const date = addDays(lastDay, i);
        days.push({ date, disabled: true, events: getEvents(date) });
      }
    }

    return (
      <Grid container>
        {days.map((day) => (
          <Grid item className={classes.day} key={day.date}>
            <Typography
              className={`${classes.dayLabel} ${
                day.disabled && classes.dayLabelDisabled
              } ${day.today && classes.dayLabelToday}`}
            >
              {format(day.date, day.disabled ? 'dd MMM' : 'dd', { locale: ptBR })}
            </Typography>
            <Grid container>
              {day.events.map((event) => (
                <Tooltip title={event.name}>
                  <Grid
                    container
                    direction="column"
                    className={classes.event}
                    onClick={() => history.push(`/communities/${communityId}/events/${event.id}`)}
                    style={{
                      backgroundImage: `url(${ event.cover || 'https://hybri.io/img/galaxy-cover-2.jpg'})`,
                    }}
                  >
                    <Typography className={classes.eventName}>
                      <span>{event.name}</span>
                    </Typography>
                  </Grid>
                </Tooltip>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  const weekLabels = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      scroll="body"
      maxWidth="lg"
      disableEscapeKeyDown
      open
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>CALENDÁRIO DE EVENTOS</span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column">
          {events ? (
            <Grid container direction="column" wrap="nowrap">
              <Grid container justify="center" spacing={2} alignItems="center">
                <Grid item>
                  <IconButton color="primary" onClick={handlePrev}>
                    <ChevronLeft />
                  </IconButton>
                </Grid>
                {/* <Grid item>
                  <Button color="primary" variant="outlined" onClick={() => setBaseDay(new Date())}>
                    HOJE
                  </Button>
                </Grid> */}
                <Grid item>
                  <Typography className={classes.calendarTitle}>
                    {format(baseDay, 'MMMM yyyy', { locale: ptBR })}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton color="primary" onClick={handleNext}>
                    <ChevronRight />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                {weekLabels.map((label) => (
                  <Grid item className={classes.weekDay} key={label}>{label}</Grid>
                ))}
              </Grid>
              {events && <Calendar />}
            </Grid>
          ) : (
            <Loading ready={!!events} content={300} />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EventForm;
