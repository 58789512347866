import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from '../reducer';

const Interessed = (dispatch, communityId, eventId) => ({
  // index: async (limit = 25) => {
  //   firestore.collection('communities').doc(communityId)
  //     .collection('articles').doc(articleId)
  //     .collection('likes').orderBy('createdAt', 'desc').limit(limit)
  //     .onSnapshot(async (snapshot) => {
  //       const likes = [];
        
  //       for (let i = 0; i < snapshot.docs.length; i++) {
  //         const doc = snapshot.docs[i];
  //         const member = await firestore.collection('members').doc(doc.id).get()
  //           .then((snap) => snap.data());
  //         likes.push({ id: doc.id, ...member });
  //       }
        
  //       dispatch(Actions.loadArticles(likes));
  //     });
  // },

  show: (memberId) => {
    firestore.collection('communities').doc(communityId)
      .collection('events').doc(eventId)
      .collection('interessed').doc(memberId)
      .onSnapshot((snapshot) => {
        dispatch(Actions.loadInteressed(snapshot.exists));
      });
  },

  store: async (memberId) => {
    const interessedRef = firestore.collection('communities').doc(communityId)
      .collection('events').doc(eventId).collection('interessed');

    return interessedRef.doc(memberId).set({ like: true, createdAt: new Date() })
      .then(async () => {
        const interessed = await interessedRef.get();

        firestore.collection('communities').doc(communityId)
          .collection('events').doc(eventId)
          .set({ interessed: interessed.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: async (memberId) => {
    const interessedRef = firestore.collection('communities').doc(communityId)
      .collection('events').doc(eventId).collection('interessed');

    return interessedRef.doc(memberId).delete()
      .then(async () => {
        const interessed = await interessedRef.get();
        
        firestore.collection('communities').doc(communityId)
          .collection('events').doc(eventId)
          .set({ interessed: interessed.size }, { merge: true });

        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },
});

export default Interessed;
