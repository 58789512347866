import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    padding: '12px 24px 24px',
    lineHeight: 1.5,
    color: theme.palette.common.grey,
  },
  calendarTitle: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 22,
    textTransform: 'uppercase',
  },
  day: {
    width: 'calc(100% / 7)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #272727',
    flexDirection: 'column',
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px`,
    minHeight: 97,
    borderBottom: 0,
    borderLeft: 0,
    '&:nth-child(-n+7)': {
      borderTop: 0,
    },
    '&:nth-child(7n)': {
      borderRight: 0,
    },
  },
  weekDay: {
    width: 'calc(100% / 7)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dayLabel: {
    color: theme.palette.common.black,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
  },
  dayLabelDisabled: {
    color: '#6f6f6f',
    fontSize: 16,
    textAlign: 'center',
  },
  dayLabelToday: {
    position: 'relative',
    '&:after': {
      content: "' '",
      background: fade(theme.palette.secondary.main, 0.3),
      width: 38,
      height: 38,
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      transform: 'translate(-24%, -18%)',
    },
  },
  event: {
    margin: '4px 0',
    borderRadius: theme.spacing(0.5),
    fontWeight: 600,
    cursor: 'pointer',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    transition: 'background 0.3s ease',
  },
  eventName: {
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(0.5),
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%)',
    borderRadius: theme.spacing(0.5),
    '& span': {
      fontSize: 14,
      color: 'white',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      lineHeight: 1.1,
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    }
  },
}));
