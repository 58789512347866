import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from '../reducer';

const Plan = (dispatch, communityId) => ({
  index: () => {
    firestore.collection('communities').doc(communityId).collection('plans')
      .onSnapshot((snapshot) => {
        const plans = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        dispatch(Actions.loadPlans(plans));
      });
  },

  store: (data) => {
    firestore.collection('communities').doc(communityId).collection('plans')
      .add({ ...data, updatedAt: new Date(), createdAt: new Date() }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Plano criado', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => {
    firestore.collection('communities').doc(communityId)
      .collection('plans').doc(id)
      .set({ ...data, updatedAt: new Date() }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Plano alterado', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => {
    firestore.collection('communities').doc(communityId)
      .collection('plans').doc(id)
      .delete()
      .then(() => {
        dispatch(Alert.show({ message: 'Plano excluído', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
});

export default Plan;
