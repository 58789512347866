/**
 * Types
 */
const Types = {
  SAVE: '@form/SAVE',
  REMOVE: '@form/REMOVE',
};

/**
 * Actions
 */
export const Actions = {
  save: (form) => ({ type: Types.SAVE, payload: { form } }),
  remove: (id) => ({ type: Types.REMOVE, payload: { id } }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  save: [],
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SAVE: {
      const newState = { ...state };
      const { form } = action.payload;
      let index = -1;

      newState.save.forEach((f, key) => {
        if (form.id === f.id) index = key;
      });

      if (index >= 0) newState.save.splice(index, 1, form);
      else newState.save.push(form);

      return newState;
    }
    case Types.REMOVE: {
      const newState = { ...state };
      const { id } = action.payload;
      let index = -1;

      newState.save.forEach((form, key) => {
        if (form.id === id) index = key;
      });

      if (index >= 0) newState.save.splice(index, 1);

      return newState;
    }
    default: {
      return state;
    }
  }
};
