/**
 * Types
 */
const Types = {
  SHOW: '@alert/SHOW',
  HIDE: '@alert/HIDE',
};

/**
 * Actions
 */
export const Actions = {
  show: ({ type, message }) => ({ type: Types.SHOW, payload: { type, message } }),
  hide: () => ({ type: Types.HIDE }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  type: 'error',
  message: 'Ocorreu um erro inesperado.',
  show: false,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SHOW: {
      const { type, message } = action.payload;
      return {
        ...state,
        type: type || INITIAL_STATE.type,
        message: message || INITIAL_STATE.message,
        show: true,
      };
    }
    case Types.HIDE: {
      return { ...state, show: false };
    }
    default: {
      return state;
    }
  }
};
