import React from 'react';
import ReactDOM from 'react-dom';
import WebFontLoader from 'webfontloader';
import { Provider } from 'react-redux';

import App from 'app/App';
import store from 'store';
import * as serviceWorker from './serviceWorker';
import './index.css';

WebFontLoader.load({
  google: {
    families: ['Nunito:300,400,800', 'Material Icons'],
  },
});

const OneSignal = window.OneSignal || [];
OneSignal.push(() => {
  const appId = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_ONESIGNAL_APP_ID_LOCAL
    : process.env.REACT_APP_ONESIGNAL_APP_ID;

  OneSignal.init({ appId });
});
OneSignal.push(['getNotificationPermission', (permission) => {
  if (permission === 'default') OneSignal.registerForPushNotifications();
}]);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
