import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material Components
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';

// Redux Actions
import useAuthController from 'store/auth/controller';

// Icons, Commons, Assets and Styles
import { CheckCircle, AlertCircle } from 'react-feather';
import video from 'assets/auth-background.vid';
import logo from 'assets/logo.png';
import styles from './style';

function SnackbarInfo({ classes, type, message }) {
  const Icon = type === 'success' ? CheckCircle : AlertCircle;
  return (
    <SnackbarContent
      className={classes[type]}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.messageSnackbar}>
          <Icon size={20} className={classes.icon} />
          {message || 'Enviamos um e-mail para a redefinição da sua senha.'}
        </span>
)}
    />
  );
}

SnackbarInfo.propTypes = {
  classes: PropTypes.shape({
    messageSnackbar: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
};

SnackbarInfo.defaultProps = {
  message: null,
};

function ResetPassword() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  // Controllers
  const Auth = useAuthController(dispatch);

  const { sentResetPassword: status } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [textSubmit, setTextSubmit] = useState('Recuperar senha');

  useEffect(() => {
    if (disableSubmit && status) {
      setDisableSubmit(false);
      setTextSubmit('Recuperar senha');
    }
  }, [status, disableSubmit]);

  const resetPassword = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    setTextSubmit('Enviando e-mail');

    Auth.resetPassword({ email });
  };

  return (
    <>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>

      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
        xs={11} sm={6} md={4}
      >
        <img alt="Logo" src={logo} className={classes.logo} />
        
        <Grid container direction="row" justify="center" alignItems="center">
          {status && (
            <SnackbarInfo
              classes={classes}
              type={status !== true ? 'error' : 'success'}
              message={status !== true ? status : null}
            />
          )}

          <form style={{ width: '100%' }} onSubmit={resetPassword}>
            <TextField
              type="email"
              id="email"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
              }}
            />

            <Grid container alignItems="center" justify="center">
              <Fab
                type="submit"
                color="secondary"
                variant="extended"
                size="large"
                className={classes.button}
                disabled={disableSubmit}
              >
                {textSubmit}
              </Fab>
            </Grid>
          </form>

          <Grid container alignItems="center" justify="center">
            <Button
              color="primary"
              style={{ margin: '32px 0 16px' }}
              onClick={() => history.goBack()}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
