import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

function Home() {
  const history = useHistory();

  console.log('home')
  
  const { communities } = useSelector((store) => store.member);

  if (communities.length > 1) {
    return <>SELECIONE A COMUNIDADE</>;
  }

  history.push(`/communities/${communities[0].id}`);

  return <></>;
}

export default Home;
