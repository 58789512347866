// Main Components
import SignIn from 'app/auth/sign-in';
import SignUp from 'app/auth/sign-up';
import ResetPassword from 'app/auth/reset-password';

// Routes Components
import SignRoute from '../components/SignRoute';

export default [
  {
    path: '/auth',
    component: SignIn,
    routerComponent: SignRoute,
    exact: true,
  },
  {
    path: '/auth/sign-in',
    component: SignIn,
    routerComponent: SignRoute,
    exact: true,
  },
  {
    path: '/auth/sign-up',
    component: SignUp,
    routerComponent: SignRoute,
    exact: true,
  },
  {
    path: '/auth/reset-password',
    component: ResetPassword,
    routerComponent: SignRoute,
    exact: true,
  },
];
