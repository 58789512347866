import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Material Components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Redux Actions
import useAuthController from 'store/auth/controller';

// Icons, Commons, Assets and Styles
import { Eye, EyeOff, AlertCircle } from 'react-feather';
import Tooltip from 'common/tooltip';
import video from 'assets/auth-background.vid';
import logo from 'assets/logo.png';
import styles from './style';

function SnackbarError({ classes, error }) {
  const SpanError = () => (
    <Grid container alignItems="center" wrap="nowrap">
      <AlertCircle size={20} className={classes.iconError} />
      <span>{error}</span>
    </Grid>
  );

  return (
    <SnackbarContent
      className={classes.error}
      aria-describedby="client-snackbar"
      message={<SpanError />}
    />
  );
}

SnackbarError.propTypes = {
  classes: PropTypes.shape({
    error: PropTypes.string.isRequired,
    messageError: PropTypes.string.isRequired,
    iconError: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string.isRequired,
};

function SignIn() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const communityId = params.get('galaxy');
  const invited = params.get('invited');

  // Controllers
  const Auth = useAuthController(dispatch);

  const { error } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    Auth.clearError();

    setDisableSubmit(true);
    await Auth.signUp({ name, email, password, agreedTerms }, communityId);
    setDisableSubmit(false);
  };

  return (
    <Grid container>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>

      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography className={classes.title}>
            ÚLTIMA CHAMADA PARA EMBARCAR NO FOGUETE HYBRI
          </Typography>

          <form onSubmit={handleSignUp}>
            <TextField
              id="name"
              label="Nome completo"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
              }}
            />

            <TextField
              type="email"
              id="email"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
              }}
            />

            <TextField
              type={showPassword ? 'text' : 'password'}
              id="password"
              variant="outlined"
              label="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.passwordInput}
              margin="normal"
              InputProps={{
                style: { background: '#000', boxShadow: '1px 4px 25px rgba(255,255,255,0.25)' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
                      enterDelay={300}
                    >
                      <IconButton
                        aria-label={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              checked={agreedTerms}
              onChange={(e) => setAgreedTerms(e.target.checked)}
              label={(
                <Typography>
                  {'Li e aceito os '}
                  <Link href="/teste" target="_blank" rel="noopener">
                    Termos de Uso
                  </Link>
                  {' e as '}
                  <Link href="/teste" target="_blank" rel="noopener">
                    Políticas de Privacidade
                  </Link>
                </Typography>
              )}
              control={<Checkbox id="remember" color="primary" />}
              style={{ position: 'relative', zIndex: 10, color: '#fff', marginTop: 16 }}
            />

            <Grid container direction="column" alignItems="center" justify="center">
              <Fab
                type="submit"
                color="secondary"
                variant="extended"
                size="large"
                className={classes.button}
                disabled={disableSubmit}
              >
                {disableSubmit ? <CircularProgress size={24} /> : 'EMBARCAR'}
              </Fab>
              <Button
                color="primary"
                style={{ margin: '32px 0 16px' }}
                onClick={() => {
                  Auth.clearError();
                  let path = '/auth/sign-in';
                  if (communityId) path += `?galaxy=${communityId}`;
                  if (invited) path += `${communityId ? '&' : '?'}invited=${invited}`;
                  history.push(path)
                }}
              >
                Já tenho passaporte
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignIn;
