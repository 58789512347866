import firebase, { firestore, fireauth, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Community } from 'store/community/reducer';
import { Actions } from './reducer';

const Member = (dispatch) => ({
  index: (communityId) => {
    firestore.collection('community-members')
      .where('communities', 'array-contains', communityId)
      .onSnapshot((snapshot) => {
        const members = snapshot.docs.map((member) => ({ id: member.id, ...member.data() }));
        dispatch(Community.loadMembers(members));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  show: (uid) => {
    firestore.collection('community-members').doc(uid).onSnapshot(async (member) => {
      if (member.exists) {
        window.OneSignal.sendTag('uid', uid);
        dispatch(Actions.load({ id: member.id, ...member.data() }));
      } else {
        dispatch(Actions.load(null));
      }
    }, (error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    });
  },

  store: (uid, data) => {
    console.log(uid, data);
    const { name, email, communityId } = data;
    const names = name.toUpperCase().split(' ');
    const len = names.length;
    let initials = '';

    if (len > 1) {
      initials = names[0].charAt(0) + names[len - 1].charAt(0);
    } else {
      initials = names[0].charAt(0) + names[0].charAt(1);
    }

    const now = new Date();

    return firestore.collection('community-members').doc(uid).set({
      name,
      email,
      initials,
      communities: communityId ? [communityId] : [],
      avatar: null,
      createdAt: now,
      updatedAt: now,
      deletedAt: null,
      agreedTerms: true,
      needComplete: true,
    });
  },

  update: (id, data) => {
    const now = new Date(Date.now());

    firestore.collection('community-members').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Informações alteradas.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  updateEmail: (email) => {
    const now = new Date();
    const user = firebase.auth().currentUser;

    user.updateEmail(email)
      .then(() => {
        firestore.collection('users').doc(user.uid)
          .set({ email, updatedAt: now }, { merge: true })
          .then(() => {
            dispatch(Alert.show({ message: 'E-mail alterado.', type: 'success' }));
          })
          .catch((error) => {
            dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
          });
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  updatePassword: ({ currentPassword, newPassword }) => {
    const user = firebase.auth().currentUser;

    fireauth.signInWithEmailAndPassword(user.email, currentPassword)
      .then(() => {
        user.updatePassword(newPassword)
          .then(() => {
            dispatch(Alert.show({ message: 'Senha alterada com sucesso.', type: 'success' }));
          })
          .catch((error) => {
            dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
          });
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (uid) => {
    const now = new Date(Date.now());

    firestore.collection('users').doc(uid)
      .set({ deletedAt: now }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Colaborador excluido com sucesso.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
});

export default Member;
