import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Community } from 'store/community/reducer';
import { Actions } from './reducer';

const Article = (dispatch, communityId) => ({
  index: async (limit = 8) => {
    firestore.collection('communities').doc(communityId).collection('articles')
      .where('deletedAt', '==', null).orderBy('createdAt', 'desc').limit(limit)
      .onSnapshot((snapshot) => {
        const articles = snapshot.docs.map((article) => ({
          id: article.id, ...article.data(), content: null,
        }));
        dispatch(Community.loadArticles(articles));
      });
  },

  show: (id) => {
    firestore.collection('communities').doc(communityId).collection('articles').doc(id)
      .onSnapshot((article) => {
        dispatch(Actions.loadArticle({ id: article.id, ...article.data() }));
      });
  },

  store: (data) => {
    firestore.collection('communities').doc(communityId).collection('articles')
      .add({ ...data, createdAt: new Date() }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Artigo criado', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => {
    firestore.collection('communities').doc(communityId)
      .collection('articles').doc(id)
      .set({ ...data, updatedAt: new Date() }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Artigo alterado', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => {
    firestore.collection('communities').doc(communityId)
      .collection('articles').doc(id)
      .set({ deletedAt: new Date() }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Artigo excluído', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  clear: () => {
    dispatch(Actions.clearArticle());
  },
});

export default Article;
