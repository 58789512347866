import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    overflow: 'overlay',
  },
  header: {
    background: theme.palette.background.default,
    boxShadow: '0px 4px 60px rgba(255, 255, 255, 0.2)',
    opacity: 0,
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
  },
  toolbarTitle: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
    opacity: 0,
  },
  logo: {
    height: 40,
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  userAvatar: {
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  chatFab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,

    animationName: 'space',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
  },
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  // filter: {
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   minWidth: '100%',
  //   minHeight: '100%',
  //   background: 'red',
  //   opacity: 0.2,
  // },

  cover: {
    zIndex: 10,
    // backgroundColor: theme.palette.primary.main,
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    borderRadius: 4,
    // boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',

    animationName: 'space',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
  },

  shortcuts: {
    zIndex: 2,
    borderRadius: 50,
    margin: `${theme.spacing(2)}px ${theme.spacing()}px`,
    padding: theme.spacing(),
    boxShadow: '0 0 80px rgba(142, 194, 255, 0.8)',
    background: '#000',
    height: 62,
    transition: 'all 0.2s ease-out',
    '&:hover': {
      color: '#000',
      background: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      boxShadow: '0 0 90px rgba(142, 194, 255, 1)',
    },
  },
  lineShortcut: {
    position: 'absolute',
    width: 3,
    background: 'linear-gradient(180deg, rgba(142, 194, 255, 0) 0%, rgba(142, 194, 255, 0.9) 50%, rgba(142, 194, 255, 0) 100%)',
    boxShadow: '0 0 18px rgba(142, 194, 255, 1)',
    zIndex: 1,
  },

  card: {
    margin: 32,
    width: '100%',
    padding: '0 16px', 
  },
  cardAvatar: {
    background: theme.palette.primary.main,
    marginRight: theme.spacing(),
  },
  btnMore: {
    margin: -32,
    width: 'calc(100% + 64px)',
    marginBottom: -52,
    padding: '12px 0',
  },
  
  channel: {
    margin: '0 -32px',
    width: 'calc(100% + 64px)',
    borderRadius: 0,
    padding: '16px 32px',
    textAlign: 'left',
    textTransform: 'inherit',
  },

  logMsg: {
    fontSize: 14,
    color: theme.palette.common.grey,
  },
}));
