import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';

// Icons and Styles
import {
  CheckCircle, Info, AlertCircle, AlertTriangle, X,
} from 'react-feather';
import styles from './style';

function AlertSnackbar() {
  const classes = styles();
  const dispatch = useDispatch();
  const { type, message, show } = useSelector((state) => state.alert);

  let Icon = AlertCircle;
  switch (type) {
    case 'success':
      Icon = CheckCircle;
      break;
    case 'warning':
      Icon = AlertTriangle;
      break;
    case 'info':
      Icon = Info;
      break;
    default:
      break;
  }

  const close = () => {
    dispatch(Alert.hide());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show}
      autoHideDuration={2500}
      onClose={close}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            <Icon size={20} className={classes.iconVariant} />
            {message}
          </span>
)}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={close}
          >
            <X size={20} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default AlertSnackbar;
