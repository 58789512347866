import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileUploader from 'react-firebase-file-uploader';
import { storage } from 'services/firebase';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';
import useMemberController from 'store/member/controller';

// Icons and styles
import {
  Plus, MoreVertical, RefreshCcw, Trash2,
} from 'react-feather';
import styles from './style';

function UploadButton({
  // eslint-disable-next-line react/prop-types
  children, onStart, onSuccess, className,
}) {
  const dispatch = useDispatch();

  const { current: member } = useSelector((store) => store.member);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={className}>
      {children}
      <FileUploader
        hidden
        filename={new Date().getTime()}
        accept="images/*"
        storageRef={storage.ref(`/profile/${member.id}/`)}
        onUploadStart={onStart}
        onUploadSuccess={onSuccess}
        onUploadError={() => {
          dispatch(Alert.show({ message: 'Não foi possível alterar a imagem.' }));
        }}
      />
    </label>
  );
}

function UploadAvatar() {
  const classes = styles();
  const dispatch = useDispatch();

  const Member = useMemberController(dispatch);

  const { current: member } = useSelector((store) => store.member);

  const [avatarMenu, setAvatarMenu] = useState(null);
  const [uploading, setUploading] = useState(false);

  const changeAvatar = async (fileName) => {
    const url = await storage.ref(`/profile/${member.id}/`).child(fileName).getDownloadURL();
    Member.update(member.id, { avatar: url });
    setUploading(false);
    setAvatarMenu(null);
  };

  const removeAvatar = async () => {
    Member.update(member.id, { avatar: null });
    setAvatarMenu(null);
  };

  if (member.avatar) {
    return (
      <>
        <Fab
          size="small"
          color="secondary"
          className={classes.avatarAction}
          onClick={(e) => setAvatarMenu(e.currentTarget)}
        >
          <MoreVertical />
        </Fab>

        <Menu
          open={!!avatarMenu}
          anchorEl={avatarMenu}
          onClose={() => setAvatarMenu(null)}
        >
          <ListItem button disabled={uploading}>
            <UploadButton
              onStart={() => setUploading(true)}
              onSuccess={changeAvatar}
              className={classes.labelListUpload}
            >
              {uploading ? (
                <>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <CircularProgress size={18} style={{ marginRight: 16 }} />
                  </ListItemIcon>
                  <ListItemText primary="Enviando..." />
                </>
              ) : (
                <>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <RefreshCcw size={18} style={{ marginRight: 16 }} />
                  </ListItemIcon>
                  <ListItemText primary="Trocar imagem" />
                </>
              )}
            </UploadButton>
          </ListItem>
          <ListItem button onClick={removeAvatar}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <Trash2 size={18} style={{ marginRight: 16 }} />
            </ListItemIcon>
            <ListItemText primary="Remover imagem" />
          </ListItem>
        </Menu>
      </>
    );
  }

  return (
    <Fab color="secondary" size="small" className={classes.avatarAction} disabled={uploading}>
      <UploadButton
        onStart={() => setUploading(true)}
        onSuccess={changeAvatar}
        className={classes.labelFabUpload}
      >
        {uploading ? <CircularProgress size={24} /> : <Plus />}
      </UploadButton>
    </Fab>
  );
}

export default UploadAvatar;
