import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 600,
    textShadow: '0px 0px 16px #fff',
    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '1.5s',
    animationIterationCount: 'infinite',
  },

  liveEvent: {
    marginTop: 80,
    background: 'url(https://hybri.io/img/galaxy-cover-2.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 4,
    height: 255,
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',

    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '2s',
    animationIterationCount: 'infinite',
  },
  liveStatus: {
    textShadow: '0px 0px 16px #000',
    color: 'white',
    padding: theme.spacing(1.5),
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 4,
    '& svg': {
      marginRight: theme.spacing(),
      filter: 'drop-shadow(0px 0px 7px rgba(0, 0, 0, .7))',
    },
  },
  liveInfo: {
    height: '50%',
    borderRadius: 4,
    padding: theme.spacing(1.5),
    color: 'white',
    fontSize: 30,
    fontWeight: 600,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)',
  },
  btnLive: {
    color: 'white',
    border: '2px solid white',
    padding: '8px 30px',
    borderRadius: 50,
    fontSize: 16,
    fontWeight: 600,
  },

  button: {
    fontSize: 16,
    borderRadius: 50,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',
    background: '#2d2d2d',

    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '3s',
    animationIterationCount: 'infinite',
  },
  nextEvent: {
    margin: `${theme.spacing()}px ${theme.spacing()}px`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 4,
    height: 150,
    cursor: 'pointer',
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.3)',
    },
    '&:first-child': { marginLeft: 0 },
    '&:last-child': { marginRight: 0 },

    animationName: 'space',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
  },
  eventoInfoContainer: {
    color: 'white',
    height: '100%',
    borderRadius: 4,
    padding: theme.spacing(),
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.9) 100%)',
  },
  eventSave: {
    cursor: 'pointer',
    fill: 'transparent',
    transition: 'fill 0.2s ease-in',
    '&:hover': {
      fill: 'white',
    },
  },
  eventName: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 4,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  noData: {
    fontSize: 20,
    color: theme.palette.common.grey,
    textTransform: 'uppercase',
  }
}));
