import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 600,
    textShadow: '0px 0px 16px #fff',
    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '0.5s',
    animationIterationCount: 'infinite',
  },

  article: {
    background: theme.palette.background.default,
    borderRadius: 4,
    cursor: 'pointer',
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.3)',
      transform: 'scale(1.01)',
    },
    '&:hover .play': {
      transform: 'scale(1.1) translate(-45%, -45%)',
    },
    '&:hover .title': {
      color: theme.palette.common.black,
    },
    animationName: 'space',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
  },
  articleMedia: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    height: 120,
    position: 'relative',
    overflow: 'hidden',
    color: 'white',
  },
  articleImage: {
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    transition: 'transform 0.2s ease-in',
  },
  articleCover: {
    padding: theme.spacing(),
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  },
  videoPlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
    transform: 'translate(-50%, -50%)',
    strokeWidth: 1,
    transition: 'transform 0.2s ease-in',
  },
  articleTitle: {
    fontSize: 20,
    fontWeight: 600,
    display: '-webkit-box',
    overflow: 'hidden',
    color: theme.palette.common.grey,
    transition: 'color 0.2s ease-in',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  articleTeaser: {
    fontSize: 14,
    marginTop: 16,
    color: theme.palette.common.grey,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  articleAuthor: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.grey,
    fontSize: 13,
  },
  articleAuthorAvatar: {
    background: theme.palette.primary.main,
    fontSize: 12,
    width: 24,
    height: 24,
    marginRight: theme.spacing(0.5),
  },
  articleInteractions: {
    color: theme.palette.common.grey,
    width: 'fit-content',
    lineHeight: 1,
  },
  articleDate: {
    color: theme.palette.common.grey,
    fontSize: 13,
    lineHeight: 1,
  },
}));
