import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import {
  Image, FileText, X, Folder,
} from 'react-feather';

function Document({ name, size, onRemove }) {
  const formatSize = () => {
    const b = size;
    if (b > 1000) {
      const kb = b / 1000;
      if (kb > 1000) {
        const mb = kb / 1000;
        if (mb > 1000) {
          const gb = mb / 1000;
          if (gb > 1000) {
            return `${(gb / 1000).toFixed(1)} TB`;
          }
          return `${gb.toFixed(1)} GB`;
        }
        return `${mb.toFixed(1)} MB`;
      }
      return `${kb.toFixed(1)} KB`;
    }
    return `${b.toFixed(1)} B`;
  };

  const getIcon = () => {
    const icons = [Image, Folder, FileText];
    const extensions = [
      'jpg,jpeg,gif,raw,png,tiff,tif,svg',
      'zip,rar,tar',
      'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
    ];

    const [extension] = name.split('.').reverse();

    if (extension) {
      for (let i = 0; i < extensions.length; i += 1) {
        if (extensions[i].indexOf(extension) >= 0) return icons[i];
      }
    }

    return icons[2];
  };

  const Icon = getIcon();

  return (
    <Grid container>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        style={{ marginBottom: 16 }}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          style={{ width: 'fit-content', overflow: 'hidden' }}
        >
          <Icon size={16} style={{ marginRight: 8, minWidth: 16 }} />
          <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {name}
          </Typography>
        </Grid>
        <Grid
          container
          wrap="nowrap"
          justify="flex-end"
          alignItems="center"
          style={{ width: 'fit-content', marginLeft: 8, position: 'relative' }}
        >
          <Typography style={{ color: '#777', fontSize: 14, whiteSpace: 'nowrap' }}>
            {`(${formatSize()})`}
          </Typography>
          <IconButton color="primary" onClick={onRemove} style={{ zIndex: 1, marginLeft: 8 }}>
            <X size={16} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

Document.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Document;
