import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    overflow: 'overlay',
    scrollBehavior: 'smooth',
  },
  header: {
    background: theme.palette.background.default,
    boxShadow: '0px 4px 60px rgba(255, 255, 255, 0.2)',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
  },
  toolbarTitle: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
  },
  logo: {
    height: 40,
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  userAvatar: {
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  chatFab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },

  content: {
    margin: 'auto',
    padding: theme.spacing(),
    width: '100%',
    maxWidth: 800,
  },

  cover: {
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    height: '60vh',
    opacity: 0.7,
    boxShadow: 'inset 0px -25px 25px 0 rgba(81, 77, 92, 0.5)',
    filter: 'blur(8px)',
  },
  iframe: {
    borderRadius: 4,
    marginTop: '-40vh',
    zIndex: 100,
    boxShadow: theme.shadows[10],
    width: 'calc(100% - 16px)',
  },

  articleTitle: {
    fontSize: 48,
    lineHeight: 1.3,
    fontWeight: 600,
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  articleTeaser: {
    fontSize: 24,
    color: theme.palette.common.grey,
    margin: `${theme.spacing(2)}px 0`,
  },
  articleContent: {
    lineHeight: 2,
    fontSize: 18,
    color: theme.palette.common.black,
    letterSpacing: 0.5,
    '& h1, h2, h3': {
      marginBottom: theme.spacing(1),
    },
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  articleDate: {
    color: '#a5a4a4',
    fontSize: 14,
  },
  divider: {
    height: 1,
    margin: '32px 0 48px',
  },

  reactions: {
    width: 'fit-content',
    position: 'fixed',
    top: '50%',
    left: 'calc(50% - 510px)',
    transform: 'translateY(-50%)',
  },
  likes: {
    width: 'fit-content',
    borderRadius: 50,
    background: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0.5),
    '& p': {
      textAlign: 'center',
      color: theme.palette.secondary.main,
      fontWeight: 600,
      padding: '8px 0',
    },
  },
  commentButton: {
    background: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(),
    marginTop: theme.spacing(1.5),
    '&:hover': {
      background: lighten(theme.palette.background.default, 0.1),
    },
  },

  // Comments
  commentAvatar: {
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    width: 48,
    height: 48,
    marginTop: theme.spacing(),
  },
  commentInput: {
    padding: theme.spacing(),
    margin: `0 ${theme.spacing(1.5)}px`,
    borderRadius: 4,
    boxShadow: theme.shadows[2],
    background: theme.palette.background.default,
    transition: 'all 0.3s ease-in',
    lineHeight: 1.7,
    '& > div': {
      lineHeight: 1.7,
    },
    '& > div:before, & > div:after': {
      content: 'none',
    },
  },
  commentBox: {
    margin: `${theme.spacing()}px 0`,
    color: theme.palette.text.primary,
  },
  commentInfo: {
    '& p': {
      fontWeight: 600,
      lineHeight: 1,
    },
    '& span': {
      fontSize: 12,
      color: theme.palette.common.grey,
      lineHeight: 1,
    },
  },
  commentBoxAvatar: {
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    width: 36,
    height: 36,
    marginRight: theme.spacing(),
    marginTop: -4,
  },
}));
