import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Material Components
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Redux Actions
import Community from 'store/community/controller';
import Member from 'store/member/controller';

// Icons, Commons, Assets and Styles
import Loading from 'common/loading';
import defaultAvatar from 'assets/default/avatar.jpg';
import defaultCover from 'assets/default/cover.jpg';
import styles from './style';

function SignIn() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const communityId = params.get('galaxy');
  const invited = params.get('invited');

  const { current: community, members } = useSelector((state) => state.community);

  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    if (communityId && !community) Community(dispatch).show(communityId);
  }, [community, communityId, dispatch]);

  useEffect(() => {
    if (communityId) {
      if (!members) Member(dispatch).index(communityId);
      else {
        if (members.length <= 4) setMemberList(members);
        else {
          const [first, second, third] = members;
          const last = { initials: `+${members.length - 4}`, avatar: null };
          setMemberList([first, second, third, last]);
        }
      }
    }
  }, [communityId, dispatch, members]);

  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ padding: 32 }}
      xs={12} sm={8} md={6} lg={8}
    >
      {community ? (
        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes.community}
          style={{ backgroundImage: `url(${community.idealize.cover || defaultCover})` }}
        >
          {invited ? (
            <Grid container className={classes.inveted}>
              Você foi convidado para fazer parte desta comunidade
            </Grid>
          ) : <div />}

          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justify="space-between"
            className={classes.communityInfos}
          >
            <Grid container alignItems="center" wrap="nowrap">
              <Avatar
                className={classes.communityAvatar}
                src={community.idealize.avatar || defaultAvatar}
              >
                {!community && <CircularProgress color="primary" />}
              </Avatar>
              <Grid container direction="column">
                <Typography className={classes.communityName}>
                  {community.idealize.name}
                </Typography>
                <Typography className={classes.communityDesc}>
                  {community.idealize.description}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              style={{ width: 'fit-content' }}
            >
              {memberList.map((member) => (
                <Avatar key={member.id} className={classes.memberAvatar} src={member.avatar}>
                  {member.initials}
                </Avatar>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loading content={400} ready={!!community} />
      )}
    </Grid>
  );
}

export default SignIn;
