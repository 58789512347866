import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    scrollBehavior: 'smooth',
    overflow: 'hidden',
  },
  logo: {
    display: 'block',
    height: '6rem',
    margin: `${theme.spacing(2)}px auto`,
    zIndex: 10,
  },
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  step: {
    width: '100vw',
  },
  stepContainer: {
    minHeight: '100vh',
    margin: '0 auto',
    padding: `0 ${theme.spacing()}px`,
    maxWidth: 700,
  },
  title: {
    position: 'relative',
    zIndex: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 28,
    textShadow: '0 0 30px rgba(255, 255, 255)',
    margin: `${theme.spacing()}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    lineHeight: 1.7,
  },
  question: {
    position: 'relative',
    zIndex: 10,
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: 24,
    textShadow: '0 0 12px rgba(255, 255, 255)',
    margin: `${theme.spacing()}px 0 ${theme.spacing(2)}px`,
    lineHeight: 1.7,
  },
  avatar: {
    width: 98,
    height: 98,
    fontSize: 35,
    background: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  avatarAction: {
    position: 'absolute',
    bottom: -8,
    right: 16,
  },
  labelListUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  labelFabUpload: {
    width: '100%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
  },
  tag: {
    position: 'relative',
    zIndex: 10,
    margin: '0 16px 16px 0',
    boxShadow: '0px 0px 30px rgba(255,255,255,0.7)',
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
    boxShadow: '1px 2px 30px rgba(255, 255, 255, 0.5)',
  },
  buttonBack: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: 50,
    background: '#000',
  },
}));
