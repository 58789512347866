import React from 'react';
import { useSelector } from 'react-redux';

// Material UI Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// Assets
import Loading from 'common/loading';
import styles from './style';

function CommunityEvents() {
  const classes = styles();

  const { current: community } = useSelector((store) => store.community);
  
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.sectionTitle}>AVISOS</Typography>
        {community ? community.alerts.map((alert) => (
          <Typography key={alert} className={classes.alert}>{alert}</Typography>
        )) : (
          <Loading ready={!!community} content={200} />
        )}
      </CardContent>
    </Card>
  );
}

export default CommunityEvents;
