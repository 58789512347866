import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  community: {
    width: '80%',
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '50vh',
    // opacity: 0.8,
    zIndex: 10,
    borderRadius: 8,
    boxShadow: '1px 4px 40px rgba(255, 255, 255, 0.4)',
  },
  communityInfos: {
    borderRadius: 8,
    padding: theme.spacing(2),
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)',
  },
  inveted: {
    background: theme.palette.secondary.main,
    padding: '8px 16px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  communityAvatar: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: `6px solid ${theme.palette.secondary.main}`,
    width: 120,
    height: 120,
    fontSize: 45,
    marginRight: theme.spacing(2),
  },
  communityName: {
    color: theme.palette.common.black,
    fontSize: 32,
    fontWeight: 600,
  },
  communityDesc: {
    color: theme.palette.common.grey,
    marginTop: theme.spacing(),
  },
  memberAvatar: {
    background: theme.palette.primary.main,
    marginRight: -8,
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    '&:last-child': { margin: 0 },
  },
}));
