import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

// Material UI Components
import Grid from '@material-ui/core/Grid';

// Gifs
// import Orbit from 'assets/loading/orbit.json';
import Planets from 'assets/loading/planets.json';

import styles from './style';

function Loading({ ready, content }) {
  const classes = styles();

  const [show, setShow] = useState(true);

  useEffect(() => {
    let didCancel = false;

    if (show && ready) {
      setTimeout(() => {
        if (!didCancel) setShow(false);
      }, 300);
    } else if (!show && !ready) {
      if (!didCancel) setShow(true);
    }

    return () => {
      didCancel = true;
    };
  }, [ready, show]);

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Planets,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{
        position: content ? 'relative' : 'absolute',
        display: show ? 'flex' : 'none',
        height: content ? `${content}px` : '100%',
        opacity: ready ? 0 : 1,
        background: content ? 'transparent' : '#212121',
      }}
    >
      <div
        className={classes.imageContainer}
        style={{ width: `${content || 200}px`, height: `${content || 200}px` }}
      >
        <Lottie isClickToPauseDisabled options={defaultOptions} width="100%" height="100%" speed={0.8} />
      </div>
    </Grid>
  );
}

Loading.propTypes = {
  ready: PropTypes.bool.isRequired,
  content: PropTypes.number,
};

Loading.defaultProps = {
  content: 0,
};

export default Loading;
