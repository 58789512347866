import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    maxWidth: 750,
    margin: '0 auto',
    padding: `0 ${theme.spacing()}px`,
  },
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  title: {
    position: 'relative',
    zIndex: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 30,
    textShadow: '0 0 30px rgba(255, 255, 255)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
  },
}));
