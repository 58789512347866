export const configMaster = {
  apiKey: "AIzaSyDapOMGpu5eoydU0bmO3OFa5Hqxs-kzNxY",
  authDomain: "hybri-vou.firebaseapp.com",
  databaseURL: "https://hybri-vou.firebaseio.com",
  projectId: "hybri-vou",
  storageBucket: "hybri-vou.appspot.com",
  messagingSenderId: "335065312952",
  appId: "1:335065312952:web:f5f3985d4dfd97ef9c8e4d",
  measurementId: "G-EMF5PMF43R"
};

export const configDev = {
  apiKey: "AIzaSyDapOMGpu5eoydU0bmO3OFa5Hqxs-kzNxY",
  authDomain: "hybri-vou.firebaseapp.com",
  databaseURL: "https://hybri-vou.firebaseio.com",
  projectId: "hybri-vou",
  storageBucket: "hybri-vou.appspot.com",
  messagingSenderId: "335065312952",
  appId: "1:335065312952:web:f5f3985d4dfd97ef9c8e4d",
  measurementId: "G-EMF5PMF43R"
};
