import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
// import UserController from 'store/user/controller';
import { Actions } from './reducer';


const Company = (dispatch) => ({
  show: (companyId) => {
    firestore.collection('companies').doc(companyId).onSnapshot((company) => {
      dispatch(Actions.loadCompany({ id: company.id, ...company.data() }));
    });
  },

  // store: (data, uid) => {
  //   firestore.collection('companies').add(data)
  //     .then(async (company) => {
  //       const User = UserController(dispatch);
  //       await User.update(uid, { company: company.id });

  //       // Create labels
  //       const labels = [
  //         { color: '#40bf67', name: 'Ingressos' },
  //         { color: '#d0d279', name: 'Conteúdos' },
  //         { color: '#DCAC33', name: 'Palestras' },
  //         { color: '#8540bf', name: 'Parcerias' },
  //         { color: '#4093bf', name: 'Contratações' },
  //         { color: '#E92DE9', name: 'Marketing' },
  //       ];
  //       labels.forEach((label) => {
  //         firestore.collection('companies').doc(company.id).collection('tasks-label').add(label);
  //       });

  //       // Create categories
  //       const categories = [
  //         { color: '#40bf67', name: 'Ingressos', type: 'in', default: true },
  //         { color: '#8540bf', name: 'Patrocínios', type: 'in', default: true },
  //         { color: '#4093bf', name: 'Fornecedores', type: 'out', default: true },
  //         { color: '#d0d279', name: 'Pessoas', type: 'out', default: true },
  //       ];
  //       categories.forEach((category) => {
  //         firestore.collection('companies').doc(company.id).collection('categories').add(category);
  //       });

  //       // Create Steps
  //       const steps = [
  //         { type: 'backlog', name: 'Para fazer', default: true, order: 0 },
  //         { type: 'working', name: 'Fazendo', default: true, order: 1 },
  //         { type: 'done', name: 'Feito', default: true, order: 2 },
  //       ];
  //       steps.forEach((step) => {
  //         firestore.collection('companies').doc(company.id).collection('tasks-step').add(step);
  //       });

  //       dispatch(Alert.show({ message: 'Empresa criada', type: 'success' }));
  //     })
  //     .catch((error) => {
  //       dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
  //     });
  // },

  update: (id, data) => {
    firestore.collection('companies').doc(id)
      .set({ ...data, updatedAt: new Date() }, { merge: true })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
});

export default Company;
