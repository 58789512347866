import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    padding: '12px 24px 24px',
    overflow: 'initial',
  },
  exitButton: {
    position: 'absolute',
    top: 16,
    right: 24,
    background: 'rgba(255, 255, 255, 0.2)',
    transition: 'background 0.2s ease-in',
    padding: 4,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    }
  },
  eventCover: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: 'calc(100% + 48px)',
    height: 200,
    margin: '-16px -24px 0',
    boxShadow: 'inset 0px -25px 25px 0 rgba(81, 77, 92, 0.5)',
  },
  eventName: {
    fontSize: 28,
    fontWeight: 600,
    color: theme.palette.primary.main,
    width: '100%',
  },
  saveButton: {
    '& svg': {
      fill: 'transparent',
      transition: 'fill 0.2s ease-in',
    },
    '&:hover svg': {
      fill: theme.palette.secondary.main,
    },
  },

  eventDescription: {
    letterSpacing: 0.7,
    lineHeight: 1.7,
    color: theme.palette.common.grey,
    marginTop: theme.spacing(0.5),
  },

  label: {
    color: theme.palette.secondary.main,
    fontSize: 13,
    marginBottom: 4,
    letterSpacing: 0.7,
  },
  value: {
    fontSize: 18,
    lineHeight: 1,
  },

  btnConfirm: {
    margin: theme.spacing(),
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    boxShadow: '4px 4px 25px rgba(255,255,255,0.1)',
    fontWeight: 600,
    borderRadius: 30,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    background: theme.palette.background.default,
    '&:hover': {
      background: lighten(theme.palette.background.default, 0.05),
      borderColor: theme.palette.success.main,
    },
  },
  btnStar: {
    margin: theme.spacing(),
    color: theme.palette.waiting.dark,
    borderColor: theme.palette.waiting.dark,
    boxShadow: '4px 4px 25px rgba(255,255,255,0.1)',
    fontWeight: 600,
    borderRadius: 30,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    background: theme.palette.background.default,
    '& svg': {
      fill: 'transparent',
      transition: 'fill 0.2s ease-in',
    },
    '&:hover': {
      background: lighten(theme.palette.background.default, 0.05),
      '& svg': {
        fill: `${theme.palette.waiting.dark} !important`,
      },
    },
  },
  labelStar: {
    width: 'fit-content',
    color: theme.palette.waiting.dark,
    marginTop: theme.spacing(),
    '& svg': {
      marginBottom: 4,
    },
  },
  labelConfirm: {
    width: 'fit-content',
    color: theme.palette.success.main,
    marginTop: theme.spacing(),
    '& svg': {
      marginBottom: 4,
    },
  },
}));
