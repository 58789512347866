/**
 * Types
 */
const Types = {
  LOAD: '@auth/LOAD',
  RESET_PASSWORD: '@auth/RESET_PASSWORD',
  ERROR: '@auth/ERROR',
};

/**
 * Actions
 */
export const Actions = {
  load: (auth) => ({ type: Types.LOAD, payload: { auth } }),
  resetPassword: (sent) => ({ type: Types.RESET_PASSWORD, payload: { sent } }),
  error: (error) => ({ type: Types.ERROR, payload: { error } }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  isAuth: false,
  isLoaded: false,
  auth: null,
  error: null,
  sentResetPassword: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD: {
      const { auth } = action.payload;
      return {
        ...state, auth, isAuth: !!auth, isLoaded: true, error: null,
      };
    }
    case Types.RESET_PASSWORD: {
      const { sent: sentResetPassword } = action.payload;
      return { ...state, sentResetPassword };
    }
    case Types.ERROR: {
      const { error } = action.payload;
      return {
        ...state, auth: null, isAuth: false, isLoaded: true, error,
      };
    }
    default: {
      return state;
    }
  }
};
