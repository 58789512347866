import firebase, { fireauth, firestore, ERRORS } from 'services/firebase';
import { Actions } from './reducer';
import { Actions as Company } from '../company/reducer';
import { Actions as MemberAction } from '../member/reducer';
import memberController from 'store/member/controller';

const Auth = (dispatch) => ({
  check: () => {
    fireauth.onAuthStateChanged((auth) => {
      if (auth) {
        const Member = memberController(dispatch);
        Member.show(auth.uid);
      }
      dispatch(Actions.load(auth));
    });
  },

  signIn: async ({ email, password, remember }, communityId) => {
    const persistence = [
      firebase.auth.Auth.Persistence.SESSION,
      firebase.auth.Auth.Persistence.LOCAL,
    ];

    // '+remember': transform boolean in integer
    await fireauth.setPersistence(persistence[+remember]);
    const { user } = await fireauth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        dispatch(Actions.error(ERRORS[error.code]));
      });

    console.log(user);
  
    const isMember = await firestore.collection('community-members').doc(user.uid).get()
      .then((doc) => doc.exists);

    console.log(isMember);
    const Member = memberController(dispatch);
    if (!isMember) {
      const { name, email } = await firestore.collection('users').doc(user.uid).get()
        .then((doc) => doc.data());
      console.log({ name, email, communityId });
      Member.store(user.uid, { name, email, communityId });
    } else {
      Member.show(user.uid);
    }

    return true;

    // return fireauth.signInWithEmailAndPassword(email, password)
    //   .then(async (auth) => {
    //     console.log(auth);
    //     const isMember = await firestore.collection('community-members').doc(auth.uid).get()
    //       .then((doc) => doc.exists);

    //     console.log(isMember);
    //     const Member = memberController(dispatch);
    //     if (!isMember) {
    //       const { name, email } = await firestore.collection('users').doc(auth.uid).get()
    //         .then((doc) => doc.data());
    //       console.log({ name, email, communityId });
    //       Member.create(auth.uid, { name, email, communityId });
    //     } else {
    //       Member.show(auth.uid);
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(Actions.error(ERRORS[error.code]));
    //   });
  },

  signUp: async ({ name, email, password, agreedTerms }, communityId) => {
    if (!agreedTerms) {
      dispatch(Actions.error('É necessário que você aceite os Termos de Uso e Políticas de Privacidade'));
      return;
    }
    if (!name) {
      dispatch(Actions.error('Precido do seu nome para o passaporte'));
      return;
    }
    if (!password) {
      dispatch(Actions.error('Escolha uma senha'));
      return;
    }

    return fireauth.createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        if (auth.user) {
          //TODO: Send mailer to Sendgrid group
          const Member = memberController(dispatch);
          Member.store(auth.user.uid, { name, email, communityId });
        } else {
          dispatch(Actions.error('Deu um problema com a nave, vamos tentar de novo'));
        }
      })
      .catch((error) => {
        dispatch(Actions.error(ERRORS[error.code]));
      });
  },

  signOut: () => {
    dispatch(Company.clearCompany());
    dispatch(MemberAction.clear());
    return fireauth.signOut();
  },

  resetPassword: async ({ email }) => {
    try {
      await fireauth.sendPasswordResetEmail(email);
      dispatch(Actions.resetPassword(true));
    } catch (error) {
      dispatch(Actions.resetPassword(ERRORS[error.code]));
    }
  },

  clearError: () => {
    dispatch(Actions.error(null));
  },
});

export default Auth;
