import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions } from './reducer';

const Channel = (dispatch, communityId) => ({
  index: (memberId) => {
    firestore.collection('communities').doc(communityId).collection('channels')
      .onSnapshot(async (snapshot) => {
        const channels = [];
        
        for (let i = 0; i < snapshot.docs.length; i++) {
          const doc = snapshot.docs[i];
          const data = doc.data();
          if (!data.members || !!data.members.find((mId) => mId === memberId)) {
            channels.push({ id: doc.id, ...data });
          }
        }
        dispatch(Actions.loadChannels(channels));

        for (let j = 0; j < channels.length; j++) {
          const doc = channels[j];

          firestore.collection('communities').doc(communityId)
            .collection('channels').doc(doc.id).collection('messages')
            .orderBy('sendAt', 'desc').limit(1)
            .onSnapshot((snap) => {
              const lastMsg = snap.docs[0] ? snap.docs[0].data() : null;
              dispatch(Actions.loadLastMessage({ channel: doc.id, lastMsg }));
            });
        }

        for (let j = 0; j < channels.length; j++) {
          const doc = channels[j];

          firestore.collection('community-members').doc(memberId)
            .collection('channels').doc(doc.id)
            .onSnapshot(async (snap) => {
              const lastSee = snap.exists ? snap.data().lastSee : null;
              dispatch(Actions.loadLastSee({ channel: doc.id, lastSee }));
            });
        }
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => {
    const now = new Date();

    return firestore.collection('communities').doc(communityId).collection('channels')
      .add({ ...data, updatedAt: now, createdAt: now })
      .then((doc) => {
        dispatch(Alert.show({ message: 'Canal de comunicação criado', type: 'success' }));
        return doc.id;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  closeChannel: (memberId, channelId) => {
    firestore.collection('community-members').doc(memberId).collection('channels').doc(channelId)
      .set({ lastSee: new Date() }, { merge: true })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => {
    const now = new Date();

    return firestore.collection('communities').doc(communityId)
      .collection('channels').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        dispatch(Alert.show({ message: 'Canal de comunicação alterado', type: 'success' }));
        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: (id) => {
    return firestore.collection('communities').doc(communityId)
      .collection('channels').doc(id)
      .delete()
      .then(() => {
        dispatch(Alert.show({ message: 'Canal de comunicação excluído', type: 'success' }));
        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },
});

export default Channel;
