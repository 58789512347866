import React, { useState, useLayoutEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

// Assets
import { HelpCircle, MessageCircle, Folder } from 'react-feather';
import video from 'assets/auth-background.vid';
import Tooltip from 'common/tooltip';
import styles from './style';
import Chat from './chat';

function Community() {
  const classes = styles();
  
  const history = useHistory();
  const { communityId } = useParams();

  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const line1 = useRef(null);
  const line2 = useRef(null);

  const { current: community } = useSelector((store) => store.community);

  const [tab, setTab] = useState('chat');

  useLayoutEffect(() => {
    if (btn1.current && btn2.current && btn3.current && line1.current && line2.current) {
      function adjustLine(from, to, line) {
        let fT = from.offsetTop + from.offsetHeight/2;
        let tT = to.offsetTop + to.offsetHeight/2;
        let fL = from.offsetLeft + from.offsetWidth/2;
        let tL = to.offsetLeft + to.offsetWidth/2;
        
        let CA = Math.abs(tT - fT);
        let CO = Math.abs(tL - fL);
        let H = Math.sqrt(CA * CA + CO * CO);
        let ANG = 180 / Math.PI * Math.acos(CA / H);

        let top = 0;
        let left = 0;
      
        if (tT > fT) {
          top = (tT-fT)/2 + fT;
        } else {
          top = (fT-tT)/2 + tT;
        }

        if (tL > fL) {
          left = (tL-fL)/2 + fL;
        } else {
          left = (fL-tL)/2 + tL;
        }
      
        if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
          ANG *= -1;
        }

        top -= H/2;
      
        line.style["-webkit-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-moz-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-ms-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-o-transform"] = 'rotate('+ ANG +'deg)';
        line.style["-transform"] = 'rotate('+ ANG +'deg)';
        line.style.top = `${top}px`;
        line.style.left = `${left}px`;
        line.style.height = H + 'px';
      }

      adjustLine(btn1.current, btn2.current, line1.current);
      adjustLine(btn2.current, btn3.current, line2.current);
    }
  }, []);
  
  return (
    <Grid container className={classes.mainContainer}>
      <video autoPlay="autoplay" loop="loop" muted className={classes.video}>
        <source src={video} />
      </video>
      <div className={classes.filter} />

      {/* <AppBar position="fixed" className={classes.header} ref={header}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.toolbarTitle} ref={title}>
            {community && community.idealize.name}
          </Typography>
          <img src={logo} alt="Logo hybri" className={classes.logo} />
          <Avatar className={classes.userAvatar}>GO</Avatar>
        </Toolbar>
      </AppBar> */}

      <Grid container style={{ zIndex: 10, height: '100vh' }}>
        <Grid item xs={12} sm={12} md={8} style={{ padding: 32 }}>
          <iframe
            title="CNN BRASIL - AO VIVO"
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/CiM35f0KYac`}
            frameBorder="0"
            allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={classes.iframe}
          />
          {/* <div style={{ background: 'white', width: '100%', height: '100%' }} /> */}
        </Grid>
        <Grid item container direction="column" xs={12} sm={12} md={4}>
          <Grid container alignItems="center" justify="space-around">
            <Tooltip TransitionComponent={Zoom} title="Chat">
              <IconButton
                color="secondary"
                className={`${classes.shortcuts} ${tab === 'chat' && classes.shortcutHover}`}
                ref={btn1}
                onClick={() => setTab('chat')}
                style={{ marginTop: 64 }}
              >
                <MessageCircle size={30} />
              </IconButton>
            </Tooltip>

            <Tooltip TransitionComponent={Zoom} title="Perguntas">
              <IconButton
                color="secondary"
                className={`${classes.shortcuts} ${tab === 'questions' && classes.shortcutHover}`}
                ref={btn2}
                onClick={() => setTab('questions')}
              >
                <Badge variant="dot" color="secondary" badgeContent={+false}>
                  <HelpCircle size={30} />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip TransitionComponent={Zoom} title="Materiais">
              <IconButton
                color="secondary"
                className={`${classes.shortcuts} ${tab === 'materials' && classes.shortcutHover}`}
                ref={btn3}
                onClick={() => setTab('materials')}
                style={{ marginTop: 48 }}
              >
                <Folder size={30} />
              </IconButton>
            </Tooltip>

            <div className={classes.lineShortcut} ref={line1} />
            <div className={classes.lineShortcut} ref={line2} />
          </Grid>
        
          <Grid container style={{ flex: 1 }}>
            {tab === 'chat' && <Chat channel />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Community;
