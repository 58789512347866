import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import MemberController from 'store/member/controller'; 

// Assets
import { Share2 } from 'react-feather';
import Tooltip from 'common/tooltip';
import defaultAvatar from 'assets/default/avatar.jpg';
import styles from './style';
// import Chat from './chat';

function Community() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { communityId } = useParams();

  // Controllers

  const { current: community, members } = useSelector((store) => store.community);

  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    if (!members) MemberController(dispatch).index(communityId);
    else {
      if (members.length <= 4) setMemberList(members);
      else {
        const [first, second, third] = members;
        const last = { id: 'plus', initials: `+${members.length - 4}`, avatar: null };
        setMemberList([first, second, third, last]);
      }
    }
  }, [communityId, dispatch, members]);
  
  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.profile}>
      <Avatar
        className={classes.communityAvatar}
        src={community ? community.idealize.avatar || defaultAvatar : ''}
      >
        {!community && <CircularProgress color="primary" />}
      </Avatar>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid container direction="column" justify="center">
          <Typography className={classes.communityName}>
            {community && community.idealize.name}
          </Typography>
          <Typography className={classes.communityDesc}>
            {community && community.idealize.description}
          </Typography>
        </Grid>
        <Grid container alignItems="flex-end" wrap="nowrap" style={{ width: 'fit-content' }}>
          <Tooltip title="Convide seus amigos">
            <IconButton color="secondary" style={{ marginRight: 16 }}>
              <Share2 />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver todos os membros">
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              style={{ width: 'fit-content', cursor: 'pointer' }}
              onClick={() => history.push(`/communities/${communityId}/members`)}
            >
              {memberList.map((member) => (
                <Avatar
                  key={member.id}
                  className={classes.memberAvatar}
                  src={member.avatar}
                  style={member.avatar ? { background: 'transparent' } : {}}
                >
                  {member.initials}
                </Avatar>
              ))}
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Community;
