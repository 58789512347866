import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    margin: '64px 32px',
    width: '100%',
    padding: '0 16px',
    boxShadow: '4px 4px 60px rgba(255, 255, 255, 0.2)',

    animationName: 'space',
    animationDuration: '4s',
    animationDelay: '0.5s',
    animationIterationCount: 'infinite',
  },
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  alert: {
    color: theme.palette.common.grey,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
}));
