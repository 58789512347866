import Home from 'app/home';

import ProfileCompleteForm from 'app/profile/complete';

import Communities from 'app/communities';

import Community from 'app/community';
import CommunityAbout from 'app/community/children/about';
import CommunityBenefits from 'app/community/children/benefits';
import CommunityMaterials from 'app/community/children/materials';
import CommunityMembers from 'app/community/children/members';
import CommunityArticle from 'app/community/children/article';
import CommunityEventView from 'app/community/children/events/view';
import CommunityEventCalendar from 'app/community/children/events/calendar';

import Transmission from 'app/transmission';

// Routes Components
import PrivateRoute from '../components/PrivateRoute';


export default [
  {
    path: '/',
    component: Home,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/complete',
    component: ProfileCompleteForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/event/:eventId',
    component: Transmission,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      // {
      //   path: '/event/:eventId/materials',
      //   component: TransmissionMaterials,
      //   routerComponent: PrivateRoute,
      //   exact: true,
      // },
    ],
  },
  {
    path: '/communities',
    component: Communities,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/communities/:communityId/articles/:articleId',
    component: CommunityArticle,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/communities/:communityId',
    component: Community,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      {
        path: '/communities/:communityId/about',
        component: CommunityAbout,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/communities/:communityId/benefits',
        component: CommunityBenefits,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/communities/:communityId/materials',
        component: CommunityMaterials,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/communities/:communityId/members',
        component: CommunityMembers,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/communities/:communityId/events/:eventId',
        component: CommunityEventView,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/communities/:communityId/events',
        component: CommunityEventCalendar,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ]
  },
];
