/**
 * Types
 */
const Types = {
  LOAD_COMPANY: '@company/LOAD_COMPANY',
  CLEAR_COMPANY: '@company/CLEAR_COMPANY',
};

/**
 * Actions
 */
export const Actions = {
  loadCompany: (current) => ({ type: Types.LOAD_COMPANY, payload: { current } }),
  clearCompany: () => ({ type: Types.CLEAR_COMPANY }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_COMPANY: {
      const { current } = action.payload;
      return { ...state, current };
    }
    case Types.CLEAR_COMPANY: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
